import React from "react";

function TableInfoCell(props) {
  const { width, cellClass, cellContent, textAlign } = props;
  return (
    <div
      className={`h-full border border-solid border-[#B3B3B3] px-2 py-4 ${cellClass}`}
      style={{
        width: width,
        textAlign: textAlign || "start",
      }}>
      <p className="text-sm leading-[18px] tracking-[.2em] truncate">{cellContent}</p>
    </div>
  );
}

export default TableInfoCell;
