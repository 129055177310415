import React from "react";
import { Link } from "react-router-dom";
import routePaths from "../../route/routePaths";

function MainMenuItem(props) {
  const { linkTo, title, description } = props;

  return (
    <Link
      to={linkTo}
      className="w-full h-[115px] rounded-l-full bg-[#4F1C00] text-white pl-32 pr-5 py-[19px] relative"
    >
      <div className="absolute w-[86px] h-[86px] top-3 left-[13px] rounded-full bg-white z-10" />

      <div className="w-full h-auto">
        <p className="text-custom-size leading-[15px] tracking-[.35em]">
          {title}
        </p>
        <div className="w-full h-[1px] bg-white my-[14px]" />
        <p className="text-[13px] leading-[16px] tracking-[.1em]">
          {description}
        </p>
      </div>
    </Link>
  );
}

function TopMenu() {
  return (
    <div className="custom-container h-auto py-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[57px] gap-y-[26px]">
        <MainMenuItem
          linkTo={routePaths.dashboard}
          title="ダッシュボード"
          description="各試験に対する案件の状況を確認できます"
        />
        <MainMenuItem
          linkTo={routePaths.order}
          title="発注書の管理"
          description="発注書を作成することができます"
        />
        <MainMenuItem
          linkTo={routePaths.dashboard}
          title="試験サンプル発送管理"
          description="試験サンプルの発送登録ができます"
        />

        <MainMenuItem
          linkTo={routePaths.chat}
          title="フェイス社への連絡"
          description="フェイスリサーチ社にチャットにて連絡することができます"
        />
        <MainMenuItem
          linkTo={routePaths.systemSettings}
          title="システム設定"
          description="自社情報の登録・変更を行うことができます"
        />
      </div>
    </div>
  );
}

export default TopMenu;
