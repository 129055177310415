import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { MenuItem } from "@mui/material";

const CommonPopoverMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function SelectPopover(props) {
  const {
    selectedValue,
    setSelectedValue,
    options,
    disabled,
    customerSelectedLabelStyle,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectWidth, setSelectWidth] = useState(0);

  const selectRef = useRef();
  useEffect(() => {
    if (selectRef.current) setSelectWidth(selectRef.current.offsetWidth);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    handleClose();
  };

  const selectedLabel = useMemo(() => {
    const selectedObj = options.find((item) => item.value === selectedValue);
    return selectedObj?.label || null;
  }, [selectedValue, options]);

  return (
    <>
      <button
        type="button"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={`w-full h-full flex border border-solid border-[#B3B3B3] rounded-[10px] !outline-none ${
          disabled ? "bg-[#B3B3B3]" : "bg-white"
        }`}
        ref={selectRef}
        disabled={disabled}
      >
        <div className="w-[calc(100%-32px)] h-full">
          <div className="w-full h-full px-2 flex items-center justify-center text-custom-size-sm">
            <p style={customerSelectedLabelStyle}>{selectedLabel}</p>
          </div>
        </div>
        <div className="w-8 h-full flex items-center justify-center rounded-r-[10px] bg-[#6C6C6C]">
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 15L0.339747 -2.76018e-07L17.6603 -1.79022e-06L9 15Z"
              fill="#D9D9D9"
            />
          </svg>
        </div>
      </button>

      <CommonPopoverMenu
        slotProps={{
          paper: {
            style: { width: selectWidth + "px" },
          },
        }}
        open={Boolean(anchorEl) && !disabled}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className="w-full h-full flex flex-col max-h-[320px] overflow-y-auto">
          {options.map((option) => (
            <MenuItem
              key={option.value}
              selected={selectedValue === option.value}
              onClick={() => handleSelect(option.value)}
              className="!font-noto-sans-jp"
            >
              {option.label}
            </MenuItem>
          ))}
        </div>
      </CommonPopoverMenu>
    </>
  );
}

export default SelectPopover;
