import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import ReactSelectSingle from "app/components/UI/Form/Select/ReactSelectSingle";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";
import { scheduleServices } from "app/services/api";
import { convertDateStringToJpFormat } from "app/utils";
import messages from "../../../../../services/api/messages";
import caseServices from "../../../../../services/api/caseServices";

function SelectTestScheduleAndCaseId({
  schedule,
  setSchedule,
  caseInfo,
  setCaseInfo,
  labelWidth = 146,
}) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    scheduleServices
      .getAll()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            allTestSchedules: res.data,
          },
          error: null,
        });
        const allTestSchedule = res.data;

        if (
          Array.isArray(allTestSchedule) &&
          !allTestSchedule.find((item) => item.id === schedule?.id)
        ) {
          setSchedule(allTestSchedule[0]);
        }
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  useEffect(() => {
    if (schedule?.id) {
      caseServices
        .getByScheduleId(schedule?.id)
        .then((data) => {
          setLoadInitState({
            isLoading: false,
            data: {
              ...loadInitState.data,
              allCaseInfos: data || [],
            },
            error: null,
          });
        })
        .catch(() => {
          toast.error(messages.callApiFail);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  const allTestScheduleOptions = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allTestSchedules))
      return [];

    return loadInitState.data?.allTestSchedules.map((item) => ({
      value: item.id,
      label: convertDateStringToJpFormat(item.testDate),
    }));
  }, [loadInitState.data?.allTestSchedules]);

  const allTestSchedule = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allTestSchedules))
      return [];

    return loadInitState.data?.allTestSchedules;
  }, [loadInitState.data?.allTestSchedules]);

  const allCaseInfosOptions = useMemo(() => {
    if (!Array.isArray(loadInitState.data?.allCaseInfos)) return [];
    return loadInitState.data?.allCaseInfos?.map((item) => ({
      value: item._id,
      label: item.caseId,
    }));
  }, [loadInitState.data?.allCaseInfos]);

  if (loadInitState.isLoading) return <PageLoading />;
  if (loadInitState.error) return <PageError />;

  if (
    Array.isArray(loadInitState.data?.allTestSchedules) &&
    loadInitState.data?.allTestSchedules.length > 0
  ) {
    return (
      <div>
        <div className="w-full h-auto">
          <ReactSelectSingle
            id="select-test-schedule"
            value={schedule?.id}
            onChange={(newValue) => {
              const schedule = allTestSchedule.find(
                (item) => item.id === newValue
              );
              setSchedule(schedule);
            }}
            label="試験スケジュール"
            options={allTestScheduleOptions}
            labelWidth={labelWidth}
          />
        </div>
        {
          <div className="mt-4 w-full h-auto">
            <ReactSelectSingle
              id="select-case-info"
              value={caseInfo?._id}
              options={allCaseInfosOptions}
              label="見積番号"
              labelWidth={labelWidth}
              onChange={(newValue) => {
                const newCaseInfo =
                  loadInitState.data?.allCaseInfos?.find(
                    (item) => item._id === newValue
                  );
                setCaseInfo(newCaseInfo);
              }}
            />
          </div>
        }
      </div>
    );
  }

  return (
    <div className="w-full h-auto">
      <PageNoData />
    </div>
  );
}

export default SelectTestScheduleAndCaseId;
