import React from "react";
import CustomerDetailContract from "../../components/Table/Customer/CustomerDetailContract";
import CustomerDetailTransactionHistory from "../../components/Table/Customer/CustomerDetailTransactionHistory";
import { Link } from "react-router-dom";
import routePaths from "../../route/routePaths";

function Dashboard() {
  return (
    <div className="custom-container h-full">
      <div className="w-full h-auto mt-3 mb-6">
        <div className="w-full h-auto pt-0 pb-[17px] flex items-center justify-between">
          <h3 className="text-custom-size">取引履歴</h3>
          <Link
            to={routePaths.topMenu}
            className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#4F1C00] text-white"
          >
            トップへ戻る
          </Link>
        </div>
      </div>
      <CustomerDetailTransactionHistory />

      <CustomerDetailContract />
    </div>
  );
}

export default Dashboard;
