import React from "react";

function TableInfoHeader(props) {
  const { tableConfig, headerFontSize } = props;
  return (
    <div className="w-full h-8 flex sticky top-0 inset-x-0">
      {Array.isArray(tableConfig)
        ? tableConfig.map((cellConfig, index) => {
            return cellConfig.CustomTableHeaderCell ? (
              <cellConfig.CustomTableHeaderCell
                key={index}
                width={cellConfig.width}
                textAlign={cellConfig.textAlign}
              />
            ) : (
              <div
                key={index}
                className="h-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center"
                style={{
                  width: cellConfig.width,
                }}
              >
                <p
                  className="text-custom-size px-1 truncate"
                  style={headerFontSize ? { fontSize: headerFontSize } : null}
                >
                  {cellConfig.title}
                </p>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default TableInfoHeader;
