import React, { useCallback, useEffect, useState } from "react";
import { customerServices } from "../../../../services/api";
import CustomPagination from "../../../UI/Pagination";
import PageError from "../../../CommonPage/PageError";
import PageLoading from "../../../CommonPage/PageLoading";
import PageNoData from "../../../CommonPage/PageNoData";
import { toast } from "react-toastify";
import TransactionHistoryTable from "./TransactionHistoryTable";
import messages from "../../../../services/api/messages";

function CustomerDetailTransactionHistory(props) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
  });

  const loadInit = useCallback((page) => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getCaseList(page)
      .then((res) => {
        setPagination({
          currentPage: Number(res.data.result.page),
          totalItems: Number(res.data.result.total),
          totalPages: Number(res.data.result.totalPages),
        });

        setLoadInitState({
          isLoading: false,
          data: res.data.result.rows,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.downloadFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit(pagination.currentPage);
  }, [loadInit, pagination.currentPage]);

  const handleChangePage = (newPage) => {
    setPagination((oldState) => ({
      ...oldState,
      currentPage: newPage,
    }));
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto">
        {loadInitState.isLoading ? (
          <div className="w-full h-[240px] overflow-x-auto">
            <PageLoading />
          </div>
        ) : null}

        {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        Array.isArray(loadInitState.data) ? (
          loadInitState.data.length > 0 ? (
            <div className="w-full h-[240px] overflow-x-auto">
              <TransactionHistoryTable
                initCaseList={loadInitState.data}
              />
            </div>
          ) : (
            <PageNoData noDataMessage="データなし!" noDataAction />
          )
        ) : null}
      </div>

      <div className="w-full h-auto">
        {pagination.totalItems > 0 ? (
          <CustomPagination
            currentPage={pagination.currentPage}
            totalItems={pagination.totalItems}
            handleChangePage={handleChangePage}
          />
        ) : null}
      </div>
    </div>
  );
}

export default CustomerDetailTransactionHistory;
