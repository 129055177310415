import React from "react";
import { NumericFormat } from "react-number-format";

function InputConcentration(props) {
  const { value, onChange, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      {isReadonly ? (
        <div className="w-full h-full flex items-center justify-center text-custom-size">{`${
          value || 0
        }w/w%`}</div>
      ) : (
        <NumericFormat
          value={value}
          onValueChange={(values) => {
            if (typeof values.floatValue === "number")
              onChange(values.floatValue);
            else onChange(0);
          }}
          allowNegative={false}
          decimalScale={2}
          valueIsNumericString={true}
          className="w-full h-full !outline-none rounded-[10px] px-3 py-3 text-center text-custom-size"
          min={0}
          thousandSeparator=","
        />
      )}
    </div>
  );
}

export default InputConcentration;
