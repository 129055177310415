import React from "react";

function SelectOptionInput(props) {
  const { value, onChange, isSelected, width, isReadonly } = props;

  return (
    <div
      className={`h-[30px] flex items-center justify-center text-[10px] leading-3 font-noto-sans-jp rounded-full bg-white ${
        isSelected
          ? "border border-solid border-[#dc5553] text-[#dc5553]"
          : "border border-solid border-[#B3B3B3] text-[#B3B3B3]"
      } ${isReadonly ? "cursor-default" : "cursor-pointer"}`}
      style={{
        width: width || "100%",
      }}
      disabled={isReadonly}
    >
      <input
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="w-full h-full rounded-full px-3 !outline-none bg-inherit"
        readOnly={isReadonly}
      />
    </div>
  );
}

export default SelectOptionInput;
