import React, { useCallback, useEffect, useState } from "react";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import Button from "app/components/UI/Button";
import { orderServices } from "app/services/api";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import TestMaterialInfoTableForm from "app/components/Table/Order/TestMaterialInfoTableForm";
import OrderSumaryUpdateTable from "app/components/Table/Order/OrderSumaryUpdateTable";
import { useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import messages from "../../../services/api/messages";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateTestContentInfoForm({
  testContentInfoItem,
  setTestContentInfoItem,
  requestFormInfo,
  setRequestFormInfo,
}) {

  const handleChangeTestMaterial = (index, newValue) => {
    const newTestMaterialInfos = [
      ...testContentInfoItem?.testMaterialInfos,
    ];
    newTestMaterialInfos[index] = newValue;
    setTestContentInfoItem({
      ...testContentInfoItem,
      testMaterialInfos: newTestMaterialInfos,
    });
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-10">
        <OrderSumaryUpdateTable
          testContentInfoItem={testContentInfoItem}
          isReadonly={false}
          requestFormInfo={requestFormInfo}
          setRequestFormInfo={setRequestFormInfo}
        />
      </div>

      {Array.isArray(testContentInfoItem?.testMaterialInfos) &&
      testContentInfoItem.testMaterialInfos.length > 0 ? (
        testContentInfoItem.testMaterialInfos.map((item, index) => (
          <TestMaterialInfoTableForm
            testMaterialNo={index + 1}
            testMaterialInfo={item}
            setTestMaterialInfo={(newValue) =>
              handleChangeTestMaterial(index, newValue)
            }
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="w-full h-full">{children}</div>
      )}
    </div>
  );
}

function UpdateOrderResult({ initOrderInfo, caseId }) {
  const [requestFormInfo, setRequestFormInfo] =
    useState(initOrderInfo);
  const [showTab, setShowTab] = useState(0);
  const [isCreating, setIsCreating] = useState(false);

  const handleChangeTestContentInfo = (index, newValue) => {
    setRequestFormInfo((oldState) => {
      const newTestContentInfos = [...oldState.testContentInfos];
      newTestContentInfos[index] = newValue;
      return {
        ...oldState,
        testContentInfos: newTestContentInfos,
      };
    });
  };

  const handleChangeTab = (event, newValue) => {
    setShowTab(newValue);
  };

  const handleUpdateSubmit = () => {
    if (isCreating) return;
    const data = {
      ...requestFormInfo,
      generatedPdf: undefined,
      id: undefined,
      caseId
    };

    setIsCreating(true);
    orderServices
      .genFileRequestForm(data)
      .then((res) => {
        const generatedWord = res?.data?.generatedWord;
        const requestFormInfoData = {
          ...requestFormInfo,
          generatedPdf: generatedWord._id,
        };

        orderServices
          .updateRequestForm(requestFormInfoData)
          .then((res) => {
            toast.success(messages.update(true));
            setIsCreating(false);
          })
          .catch((error) => {
            console.log("error: ", error);
            toast.error(messages.update(false));
            setIsCreating(false);
          });
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error(messages.callApiFail);
        setIsCreating(false);
      });
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-between mb-4">
          <h3 className="text-custom-size">試験内容</h3>

          <Button type="primary" onClick={handleUpdateSubmit}>
            登録する
          </Button>
        </div>

        <div className="w-full">
          <div className="border-b w-full overflow-x-auto">
            <Tabs
              value={showTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              className="mb-3"
              variant="scrollable"
              scrollButtons="auto"
            >
              {requestFormInfo.testContentInfos.map(
                (testContentInfoItem, index) => {
                  return testContentInfoItem?.testMaterialInfos
                    ?.length > 0 ? (
                    <Tab
                      label={
                        testContentInfoItem?.testItemName ||
                        `Tab ${index + 1}`
                      }
                      {...a11yProps(index)}
                    />
                  ) : null;
                }
              )}
            </Tabs>
          </div>

          {requestFormInfo.testContentInfos.map(
            (testContentInfoItem, index) => {
              return testContentInfoItem?.testMaterialInfos?.length >
                0 ? (
                <CustomTabPanel value={showTab} index={index}>
                  <CreateTestContentInfoForm
                    testContentInfoItem={testContentInfoItem}
                    setTestContentInfoItem={(newValue) =>
                      handleChangeTestContentInfo(index, newValue)
                    }
                    requestFormInfo={requestFormInfo}
                    setRequestFormInfo={setRequestFormInfo}
                  />
                </CustomTabPanel>
              ) : null;
            }
          )}
        </div>
      </div>

      <PageLoadingBackdrop open={isCreating} />
    </>
  );
}

function UpdateOrderContent() {
  const { id } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const caseId = searchParams.get('caseId')

  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    orderServices
      .searchById(id)
      .then((res) => {
        setLoadInitState((oldState) => ({
          ...oldState,
          isLoading: false,
          data: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState((oldState) => ({
          ...oldState,
          isLoading: false,
          data: null,
          error: error,
        }));
      });
  }, [id]);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <div className="w-full h-auto">
      {loadInitState.isLoading ? <PageLoading /> : null}
      {!loadInitState.isLoading && loadInitState.error ? (
        <PageError />
      ) : null}
      {!loadInitState.isLoading && !loadInitState.error ? (
        loadInitState.data && loadInitState.data?.id ? (
          <UpdateOrderResult initOrderInfo={loadInitState.data} caseId={caseId}/>
        ) : (
          <PageNoData />
        )
      ) : null}
    </div>
  );
}

function UpdateOrder() {
  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.order, linkTitle: "発注書の検索" },
        {
          isLabel: true,
          linkTitle: "発注書の修正",
        },
        {
          linkTo: routePaths.orderCreate,
          linkTitle: "発注書の作成",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
    >
      <UpdateOrderContent />
    </ContentLayout>
  );
}

export default UpdateOrder;
