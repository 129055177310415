import { DEFAULT_ITEM_PER_PAGE } from "app/constants";
import client from "./client";

export const fakeResponseDataApi = (responseData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: responseData,
      });
    }, 1000);
  });
};

const getDetail = () => {
  return client.get(`/customer-info/get-customer-info`).then((res) => {
    if (res.data?.success && res.data?.result?._id)
      return {
        data: {
          id: res.data.result?._id,
          companyNameKanji: res.data.result?.companyNameKanji,
          companyNameKana: res.data?.result?.companyNameKana,
          customerId: res.data.result?.customerId,
          email: res.data.result.email,
          password: res.data.result?.password,
          postalCode: res.data.result?.postalCode,
          prefecture: res.data.result?.prefecture,
          address1: res.data.result?.address1,
          address2: res.data.result?.address2,
          address3: res.data.result?.address3,
          phone1: res.data.result?.phone1,
          phone2: res.data.result?.phone2,
          billing: res.data.result?.billing
            ? {
                id: res.data.result?.billing?._id,
                ...res.data.result?.billing,
              }
            : null,
          pic: res.data.result?.pic
            ? {
                id: res.data.result?.pic?._id,
                ...res.data.result?.pic,
              }
            : null,
          quotationBasics: Array.isArray(res.data.result?.quotation_basics)
            ? res.data.result?.quotation_basics
            : [],
          contracts: Array.isArray(res.data.result?.customer_contracts)
            ? res.data.result?.customer_contracts.map((item) => ({
                id: item._id,
                contractId: item.contractId,
                name: item.name,
                contractDate: item.contractDate,
                progress: item.progress,
              }))
            : [],
        },
      };
    throw new Error("Malformed data!");
  });
};

const updateInfo = (data) => {
  return client.post("/customer-info/updateByCustomer", data).then((res) => {
    if (res.data?.success && res.data?.result)
      return {
        data: {
          id: res.data.result?._id,
        },
      };
    throw new Error("Malformed data!");
  });
};

const getCaseList = async (page) => {
  return client.get("/customer-info/get-case-info", {
    params: {
      page: Number(page),
      perPage: DEFAULT_ITEM_PER_PAGE,
    },
  });
};

const getAllContract = () => {
  return client
    .get("/customer-info/get-all-contract-with-customer")
    .then((res) => {
      if (res.data?.success && res.data?.result)
        return {
          data: Array.isArray(res.data.result?.customer_contracts)
            ? res.data.result?.customer_contracts.map((item) => ({
                ...item,
                customerName: res.data.result?.companyNameKanji || "",
              }))
            : [],
        };
      throw new Error("Malformed data!");
    });
};

const customerServices = {
  getDetail,
  updateInfo,
  getAllContract,
  getCaseList,
};
export default customerServices;
