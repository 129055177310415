import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageNoData from "app/components/CommonPage/PageNoData";
import PageError from "app/components/CommonPage/PageError";

import TableInfo from "app/components/Table/CommonComponents/TableInfo";

import {
  DEFAULT_JP_DATE_FORMAT,
  contractProgressType,
  contractProgressTypeLabel,
  fileNameType,
} from "app/constants";
import {
  commonServices,
  contractServices,
  customerServices,
} from "app/services/api";
import moment from "moment";
import PageLoadingBackdrop from "../../../CommonPage/PageLoadingBackdrop";
import messages from "../../../../services/api/messages";
import TableInfoHeader from "../../CommonComponents/TableInfo/TableInfoHeader";
import TableInfoCell from "../../CommonComponents/TableInfo/TableInfoCell";
import Popup from "../CustomerDetailTransactionHistory/Popup";
import PrimaryBtn from "../../../UI/Button/PrimaryBtn";

const tableConfig = [
  {
    title: "契約書ID",
    width: "20%",
    textAlign: "center",
    dataKey: "contract",
    CustomTableCell: CustomContractIdCell,
  },
  {
    title: "契約書名",
    width: "36%",
    textAlign: "center",
    dataKey: "name",
  },
  {
    title: "締結日",
    width: "22%",
    textAlign: "center",
    dataKey: "contractDate",
  },
  {
    title: "進捗",
    width: "22%",
    textAlign: "center",
    dataKey: "progress",
  },
];

function CustomContractIdCell(props) {
  const {
    contractInfo,
    contractProgress,
    width,
    textAlign,
    cellClass,
    setContractInfo,
  } = props;

  const [isDownloading, setIsDownloading] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleDownloadContractFile = () => {
    if (contractInfo?.generatedWord) {
      setIsDownloading(true);
      const fileName = fileNameType["contract"];
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      commonServices
        .downloadPDFWithFileId(
          contractInfo?.generatedWord,
          `${fileName}_${contractInfo.customerName}_${timeStamp}`
        )
        .then(() => {
          setIsDownloading(false);
        })
        .catch(() => {
          toast.error(messages.downloadFail);
          setIsDownloading(false);
        });
    }
  };

  const handleRightClickInvoice = (e) => {
    e.preventDefault();
    if (contractProgress === contractProgressType.submitted)
      setAnchor(e.target);
  };

  const handleUpdateContractProgress = (newProgress) => {
    if (isUpdating || contractProgress !== contractProgressType.submitted)
      return;

    setIsUpdating(true);

    contractServices
      .updateContractProgress(contractInfo?._id, newProgress)
      .then(() => {
        setIsUpdating(false);
        setAnchor(null);
        setContractInfo({
          ...contractInfo,
          progress: newProgress,
        });
      })
      .catch(() => {
        toast.error(messages.callApiFail);
        setIsUpdating(false);
      });
  };

  return (
    <>
      <div
        className={`h-full border border-solid border-[#B3B3B3] px-2 py-4 ${cellClass}`}
        style={{
          width: width,
          textAlign: textAlign || "start",
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate">
          <button
            onClick={handleDownloadContractFile}
            onContextMenu={handleRightClickInvoice}
            className={contractInfo?.generatedWord ? "text-[#08978E]" : ""}
          >
            {contractInfo?.contractId || ""}
          </button>
        </p>
      </div>

      <Popup
        id={`contract-${contractInfo?._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        <div className="flex gap-2">
          <PrimaryBtn
            text="承認"
            onClick={() =>
              handleUpdateContractProgress(contractProgressType.completed)
            }
          />
          <PrimaryBtn
            text="差し戻し"
            onClick={() =>
              handleUpdateContractProgress(contractProgressType.rejected)
            }
          />
          <PrimaryBtn text="戻る" onClick={() => setAnchor(null)} />
        </div>
      </Popup>
      <PageLoadingBackdrop open={isUpdating || isDownloading} />
    </>
  );
}

function CustomerDetailContractContent({ loadInitData }) {
  const [contractList, setContractList] = useState(loadInitData);

  const handleChangeContractList = (newContract, index) => {
    const newContractList = [...contractList];
    newContractList[index] = newContract;
    setContractList(newContractList);
  };

  return (
    <>
      <div className="w-full h-full overflow-auto relative">
        <TableInfoHeader tableConfig={tableConfig} />

        {Array.isArray(contractList) && contractList.length > 0 ? (
          contractList.map((rowData, index) => (
            <div className="w-full h-[52px] flex">
              <CustomContractIdCell
                width={tableConfig[0].width}
                contractProgress={rowData?.progress}
                contractInfo={rowData}
                setContractInfo={(newContract) =>
                  handleChangeContractList(newContract, index)
                }
                textAlign={tableConfig[0].textAlign}
                cellClass={tableConfig[0]?.cellClass}
              />
              <TableInfoCell
                width={tableConfig[1].width}
                textAlign={tableConfig[1].textAlign}
                cellClass={tableConfig[1]?.cellClass}
                cellContent={
                  rowData?.[tableConfig[1].dataKey] !== undefined
                    ? rowData?.[tableConfig[1].dataKey]
                    : ""
                }
              />
              <TableInfoCell
                width={tableConfig[2].width}
                textAlign={tableConfig[2].textAlign}
                cellClass={tableConfig[2]?.cellClass}
                cellContent={
                  rowData?.[tableConfig[2].dataKey] !== undefined
                    ? moment(rowData?.[tableConfig[2].dataKey]).format(
                        DEFAULT_JP_DATE_FORMAT
                      )
                    : ""
                }
              />
              <TableInfoCell
                width={tableConfig[2].width}
                textAlign={tableConfig[2].textAlign}
                cellClass={tableConfig[2]?.cellClass}
                cellContent={contractProgressTypeLabel[rowData.progress] || ""}
              />
            </div>
          ))
        ) : (
          <PageNoData />
        )}
      </div>
    </>
  );
}

function CustomerDetailContract() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getAllContract()
      .then((res) => {
        console.log("Contract list ", res.data);

        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.downloadFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <div className="w-full h-auto mb-6">
      <div className="w-full h-auto text-custom-size mb-4">契約書締結状況</div>

      {loadInitState.isLoading ? <PageLoading /> : null}

      {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

      {!loadInitState.isLoading && !loadInitState.error ? (
        Array.isArray(loadInitState.data) && loadInitState.data.length > 0 ? (
          <CustomerDetailContractContent loadInitData={loadInitState.data} />
        ) : (
          <PageNoData noDataMessage="まだ契約はありません！" />
        )
      ) : null}
    </div>
  );
}

export default CustomerDetailContract;
