/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCurrentCustomerInfoId, socketConfig } from "app/services/socket";
import ContentLayout from "./Components/ContentLayout";
import MessageCard from "./Components/MessageCard";
import Button from "app/components/UI/Button";
import { commonServices } from "app/services/api";
import {
  ADMIN_CHAT_ID,
  DEFAULT_MSG_NUMBER_PER_PAGE,
} from "../../constants";
import moment from "moment/moment";
import ChatBox from "./Components/ChatBox";
import useVerifyToken from "../../hooks/useVerifyToken";

function ChatContent({ socket }) {
  const [msgList, setMsgList] = useState([]);
  const [selectingId, setSelectingId] = useState(ADMIN_CHAT_ID);
  const [listContact, setListContact] = useState([]);
  const textAreaRef = useRef();
  const msgListRef = useRef(null);
  // Need clear before select another
  const [isLoadingMoreMsg, setIsLoadingMoreMsg] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [prevHeight, setPrevHeight] = useState(0);
  const [msgVal, setMsgVal] = useState("");

  //Function

  const scrollBottom = () => {
    const msgListEl = document.getElementById("msg-list-id");
    msgListEl.scrollTo(0, msgListEl.scrollHeight);
  };

  const scrollToKeepCurrentPosition = () => {
    const msgListEl = document.getElementById("msg-list-id");
    const scrollDistance =
      msgListRef?.current?.offsetHeight - prevHeight;
    setPrevHeight(msgListRef?.current?.offsetHeight);
    msgListEl.scrollTo(0, scrollDistance);
  };

  const clearStateWhenChangeObjId = () => {
    setIsLoadingMoreMsg(false);
    setOffset(0);
    setHasMore(false);
    setPrevHeight(0);
    setMsgVal("");
  };

  const handleSendMsg = () => {
    if (selectingId && msgVal) {
      socket?.emit("message", {
        message: msgVal.replaceAll("\n", "<br/>"),
        toId: selectingId,
        fromId: getCurrentCustomerInfoId(),
      });
      textAreaRef.current.focus();
    }
  };

  const handleScrollMsgList = (e) => {
    if (e?.target.scrollTop === 0 && hasMore) {
      setOffset((prev) => prev + 1);
      setIsLoadingMoreMsg(true);
      socket?.emit("load-more-msg", {
        toId: selectingId,
        fromId: getCurrentCustomerInfoId(),
        offset: offset + 1,
        perPage: DEFAULT_MSG_NUMBER_PER_PAGE,
      });
    }
  };

  function onConnect() {
    console.log("Socket connected");
  }
  function onDisconnect() {
    console.log("Socket disconnected");
  }
  function onInitMsgEvent(value) {
    const { prevMsg, hasMore } = value;
    setHasMore(hasMore);
    const formatedList = prevMsg?.reverse().map((msgItem, index) => {
      const { message, fromId, toId, _id, createdAt } = msgItem || {};
      return {
        content: message,
        isIncomingMsg: fromId !== getCurrentCustomerInfoId(),
        fromId,
        toId,
        _id,
        createdAt,
      };
    });
    setMsgList(formatedList);
  }
  function onLoadMoreMsgEvent(value) {
    const { prevMsg, hasMore } = value;
    setHasMore(hasMore);
    const formatedList = prevMsg?.reverse().map((msgItem) => {
      const { message, fromId, toId, _id, createdAt } = msgItem || {};
      const isIncomingMsg = fromId !== getCurrentCustomerInfoId();
      return {
        content: message,
        isIncomingMsg,
        fromId,
        toId,
        _id,
        createdAt,
      };
    });
    setMsgList((prev) => {
      return [...formatedList, ...prev];
    });
  }

  function onSendSuccessEvent(value) {
    const { message, fromId, toId, createdAt, _id } = value || {};
    console.log('value', value)
    const isIncomingMsg = fromId !== getCurrentCustomerInfoId();
    setMsgList((prev) => [
      ...prev,
      {
        content: message,
        isIncomingMsg,
        fromId,
        toId,
        createdAt,
        _id,
      },
    ]);
    setMsgVal("");
  }

  // Hook
  useEffect(() => {
    socket?.on("connect", onConnect);
    socket?.on("send-success-message", onSendSuccessEvent);
    socket?.on("load-more-msg", onLoadMoreMsgEvent);
    socket?.on("join-room", onInitMsgEvent);
    socket?.on("disconnect", onDisconnect);
    return () => {
      socket?.off("connect", onConnect);
      socket?.off("disconnect", onDisconnect);
      socket?.off("join-room", onInitMsgEvent);
      socket?.off("send-success-message", onSendSuccessEvent);
      socket?.off("load-more-msg", onLoadMoreMsgEvent);
    };
  }, [socket]);

  useEffect(() => {
    if (selectingId) {
      socket?.emit("join-room", {
        toId: selectingId,
        fromId: getCurrentCustomerInfoId(),
        offset: 0,
        perPage: DEFAULT_MSG_NUMBER_PER_PAGE,
      });
    }
    clearStateWhenChangeObjId();
    // if (selectingId) {
    //   window.history.replaceState(null, null, `/chat?id=${selectingId}`);
    // } else {
    //   window.history.replaceState(null, null, `/chat`);
    // }
  }, [selectingId]);

  useEffect(() => {
    commonServices
      .getAllAccountList()
      .then((res) => {
        setListContact(
          res?.data?.result.rows.filter(
            ({ _id }) => _id !== getCurrentCustomerInfoId()
          )
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (isLoadingMoreMsg) {
      scrollToKeepCurrentPosition();
    } else {
      scrollBottom();
      setPrevHeight(msgListRef?.current?.offsetHeight);
    }

    setIsLoadingMoreMsg(false);
  }, [msgList]);

  const formatedMsgList = useMemo(() => {
    // check duplicate msg and rearrange
    const result = [];
    msgList.forEach((item) => {
      const { _id, fromId, toId } = item || {};
      const isCurrentObject =
        fromId === selectingId || toId === selectingId;
      if (
        result.every(({ _id: resId }) => {
          return resId !== _id;
        }) &&
        isCurrentObject
      ) {
        result.push(item);
      }
    });
    return result.sort(({ createdAt: a }, { createdAt: b }) => {
      return moment(a).diff(moment(b), "miliseconds");
    });
  }, [msgList]);

  const getCurrentCoName = useMemo(() => {
    const { info } =
      listContact?.find(({ _id }) => selectingId === _id) || {};
    if (info) {
      return info?.firstNameKanji + info?.lastNameKanji;
    }
    return;
  }, [selectingId]);

  return (
    <ContentLayout
      selectingId={selectingId}
      onSelect={setSelectingId}
      listContact={listContact}
    >
      <div className="h-full flex flex-col flex-1 gap-[1.3em]">
        <div className="w-full px-4 min-h-[40px] py-2 bg-[#4F1C00] text-white">
          {getCurrentCoName}
        </div>
        <div
          onScroll={handleScrollMsgList}
          id="msg-list-id"
          className="flex-1 overflow-y-auto"
        >
          <div ref={msgListRef} className="flex flex-col gap-4">
            {formatedMsgList?.map(
              ({ isIncomingMsg, content, _id }) => {
                // console.log("content", content);
                return (
                  <MessageCard
                    key={_id}
                    isIncomingMsg={isIncomingMsg}
                    content={content}
                  />
                );
              }
            )}
          </div>
        </div>
        {selectingId && (
          <div className="flex-[0_0_82px]">
            <ChatBox
              textAreaRef={textAreaRef}
              msgVal={msgVal}
              setMsgVal={setMsgVal}
              handleSendMsg={handleSendMsg}
            />
            <div className="flex justify-end gap-4 mt-2">
              <Button type="danger">クリア</Button>
              <Button onClick={handleSendMsg}>送信する</Button>
            </div>
          </div>
        )}
      </div>
    </ContentLayout>
  );
}

export default function ChatPage() {
  const [socket, setSocket] = useState();
  useVerifyToken();
  useEffect(() => {
    setSocket(socketConfig());
  }, []);
  if (socket) {
    return <ChatContent socket={socket} />;
  }
}
