import React from "react";
import SelectOption from "./components/SelectOption";
import SelectOptionOther from "./components/SelectOptionOther";

const dilutionMediaOptions = [
  {
    value: "注射用蒸留水",
    label: "注射用蒸留水",
  },
  {
    value: "白色ワセリン",
    label: "白色ワセリン",
  },
];

function SelectDilutionMedia(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 items-center justify-center gap-2.5 py-2 px-2 lg:px-[30px]">
        <SelectOption
          label={dilutionMediaOptions[0].label}
          value={dilutionMediaOptions[0].value}
          onSelect={onSelect}
          isSelected={selectedValue === dilutionMediaOptions[0].value}
          isReadonly={isReadonly}
        />
        <SelectOption
          label={dilutionMediaOptions[1].label}
          value={dilutionMediaOptions[1].value}
          onSelect={onSelect}
          isSelected={selectedValue === dilutionMediaOptions[1].value}
          isReadonly={isReadonly}
        />
        <SelectOptionOther
          selectedValue={selectedValue}
          onSelect={onSelect}
          isReadonly={isReadonly}
          otherPrefix="その他"
        />
      </div>
    </div>
  );
}

export default SelectDilutionMedia;
