import React from "react";
import SelectOption from "./components/SelectOption";

const skinDyingOptions = [
  {
    value: false,
    label: "なし",
  },
  {
    value: true,
    label: "あり",
  },
];

function SelectSkinDying(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full flex items-center justify-start gap-6 p-2">
        <SelectOption
          label={skinDyingOptions[0].label}
          value={skinDyingOptions[0].value}
          onSelect={onSelect}
          isSelected={selectedValue === skinDyingOptions[0].value}
          width="123px"
          isReadonly={isReadonly}
        />
        <SelectOption
          label={skinDyingOptions[1].label}
          value={skinDyingOptions[1].value}
          onSelect={onSelect}
          isSelected={selectedValue === skinDyingOptions[1].value}
          width="123px"
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

export default SelectSkinDying;
