const messages = {
  //common
  common_error: "エラーが発生しました。もう一度お試しください。",
  callApiFail: "APIの呼び出しに失敗しました。 もう一度試してください。",
  downloadFail: "ダウンロードに失敗しました。もう一度お試しください。",
  update: (isSuccessFul) =>
    isSuccessFul
      ? "更新が完了しました。"
      : "更新が失敗しました。もう一度お試しください。",
  create: (isSuccessFul) =>
    isSuccessFul
      ? "作成が完了しました。"
      : "作成が失敗しました。もう一度お試しください。",
  confirm: (isSuccessFul) =>
    isSuccessFul
      ? "正常に確認できました。"
      : "確認できませんでした。 もう一度試してください。",
  delete: (isSuccessFul) =>
    isSuccessFul
      ? "削除が完了しました。"
      : "削除が失敗しました。もう一度お試しください。",
  required: (fieldName) => `${fieldName}は必須です。`,
  handleFileFail: "ファイルの処理の際はエラーが発生しました。",
  //screem
  planExist:
    "試験計画書は既に作成しました。修正する場合は、試験計画書の修正で修正 していください。",
  planNotExist:
    "試験計画書はまだ作成しません。試験計画書の作成から作成していください。",
  invoiceExist: (isExist) =>
    isExist
      ? "請求書は既に作成しました。修正する場合は、請求書の修正で修正していください。"
      : "請求書はまだ作成しません。請求書の作成から作成していください。",
  quotationExist: (isExist) =>
    isExist
      ? "見積書は既に作成しました。修正する場合は、見積書の修正で修正していください。"
      : "見積書はまだ作成しません。見積書の作成から作成していください。",

  loadQuotationFail: "",
  loadCustomerFail: "",
  custRegisterFail: "",
  custUpdateFail: "",
  userUpdateFail: "",
  userRegisterFail: "",
};

export default messages;
