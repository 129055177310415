import React from "react";

function PrimaryBtn({ text, onClick, className }) {
  return (
    <button
      className={`w-24 h-[38px] rounded-[10px] flex items-center justify-center bg-[#4F1C00] ${className}`}
      onClick={onClick}
    >
      <span className="text-white text-[13px]">{text}</span>
    </button>
  );
}

export default PrimaryBtn;
