import React, { useCallback, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ContentLayout from "app/layouts/ContentLayout";

import routePaths from "app/route/routePaths";
import Button from "app/components/UI/Button";
import SelectTestScheduleAndCaseId from "app/components/UI/Form/Select/SelectTestScheduleAndCaseId";
import {
  commonServices,
  customerServices,
  orderServices,
} from "app/services/api";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import TestMaterialInfoTableForm from "app/components/Table/Order/TestMaterialInfoTableForm";
import OrderSumaryUpdateTable from "app/components/Table/Order/OrderSumaryUpdateTable";
import PageLoadingBackdrop from "../../../components/CommonPage/PageLoadingBackdrop";
import { fileNameType } from "../../../constants";
import moment from "moment";
import messages from "../../../services/api/messages";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateTestContentInfoForm(props) {
  const {
    testContentInfoItem,
    setTestContentInfoItem,
    requestFormInfo,
    setRequestFormInfo,
  } = props;

  const handleChangeTestMaterial = (index, newValue) => {
    const newTestMaterialInfos = [
      ...testContentInfoItem?.testMaterialInfos,
    ];
    newTestMaterialInfos[index] = newValue;
    setTestContentInfoItem({
      ...testContentInfoItem,
      testMaterialInfos: newTestMaterialInfos,
    });
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-10">
        <OrderSumaryUpdateTable
          testContentInfoItem={testContentInfoItem}
          isReadonly={false}
          requestFormInfo={requestFormInfo}
          setRequestFormInfo={setRequestFormInfo}
        />
      </div>

      {Array.isArray(testContentInfoItem?.testMaterialInfos) &&
      testContentInfoItem.testMaterialInfos.length > 0 ? (
        testContentInfoItem.testMaterialInfos.map((item, index) => (
          <TestMaterialInfoTableForm
            testMaterialNo={index + 1}
            testMaterialInfo={item}
            setTestMaterialInfo={(newValue) =>
              handleChangeTestMaterial(index, newValue)
            }
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="w-full h-full">{children}</div>
      )}
    </div>
  );
}

function CreateOrderForm({ initOrderInfo, testScheduleId, caseId }) {
  const [requestFormInfo, setRequestFormInfo] =
    useState(initOrderInfo);
  const [showTab, setShowTab] = useState(0);
  const [isCreating, setIsCreating] = useState(false);

  const handleChangeTestContentInfo = (index, newValue) => {
    setRequestFormInfo((oldState) => {
      const newTestContentInfos = [...oldState.testContentInfos];
      newTestContentInfos[index] = newValue;
      return {
        ...oldState,
        testContentInfos: newTestContentInfos,
      };
    });
  };

  const handleChangeTab = (event, newValue) => {
    setShowTab(newValue);
  };

  const handleConfirmCreate = () => {
    if (isCreating) return;

    const data = {
      ...requestFormInfo,
      testSchedule: testScheduleId,
      generatedPdf: undefined,
      caseId,
    };

    setIsCreating(true);
    orderServices
      .genFileRequestForm(data)
      .then((res) => {
        const generatedWord = res?.data?.generatedWord;
        setRequestFormInfo((oldState) => ({
          ...oldState,
          generatedPdf: generatedWord._id,
        }));
        setIsCreating(false);

        customerServices
          .getDetail()
          .then((res) => {
            const customerName = res?.data?.companyNameKanji || "";

            const fileType = fileNameType["requestForm"];
            const timeStamp = moment().format("YYYYMMDDHHmmss");

            setTimeout(() => {
              commonServices
                .downloadWithFileId(
                  generatedWord._id,
                  "docx",
                  `${fileType}_${customerName}_${timeStamp}`
                )
                .catch(() => {
                  toast.error(messages.downloadFail);
                });
            }, 300);
            setTimeout(() => {
              commonServices
                .downloadPDFWithFileId(
                  generatedWord._id,
                  `${fileType}_${customerName}_${timeStamp}`
                )
                .catch(() => {
                  toast.error(messages.downloadFail);
                });
            }, 500);
          })
          .catch(() => toast.error(messages.callApiFail));
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error(messages.callApiFail);
        setIsCreating(false);
      });
  };

  const handleSubmitCreate = () => {
    if (isCreating || !requestFormInfo?.generatedPdf) return;

    const data = {
      ...requestFormInfo,
      testSchedule: testScheduleId,
      caseId,
    };

    setIsCreating(true);
    orderServices
      .createRequestForm(data)
      .then(() => {
        toast.success(messages.create(true));
        setIsCreating(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error(messages.create(false));
        setIsCreating(false);
      });
  };

  const handleResetForm = () => {
    setRequestFormInfo(initOrderInfo);
    toast.success("フォームのリセットが成功しました。");
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full h-auto flex items-center justify-start mb-4">
          <h3 className="text-custom-size">試験内容</h3>
        </div>

        <div className="w-full">
          <div className="border-b w-full overflow-x-auto">
            <Tabs
              value={showTab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              className="mb-3"
              variant="scrollable"
              scrollButtons="auto"
            >
              {requestFormInfo.testContentInfos.map(
                (testContentInfoItem, index) => {
                  return testContentInfoItem?.testMaterialInfos
                    ?.length > 0 ? (
                    <Tab
                    key={`tab-${index}`}
                      label={
                        testContentInfoItem?.testItemName ||
                        `Tab ${index + 1}`
                      }
                      {...a11yProps(index)}
                    />
                  ) : null;
                }
              )}
            </Tabs>
          </div>

          {requestFormInfo.testContentInfos.map(
            (testContentInfoItem, index) => {
              return testContentInfoItem?.testMaterialInfos?.length >
                0 ? (
                <CustomTabPanel value={showTab} index={index}>
                  <CreateTestContentInfoForm
                    testContentInfoItem={testContentInfoItem}
                    setTestContentInfoItem={(newValue) =>
                      handleChangeTestContentInfo(index, newValue)
                    }
                    requestFormInfo={requestFormInfo}
                    setRequestFormInfo={setRequestFormInfo}
                  />
                </CustomTabPanel>
              ) : null;
            }
          )}
        </div>

        <div className="w-full h-auto flex items-center justify-end gap-4 -mt-6">
          <Button type="danger" onClick={handleResetForm}>
            クリア
          </Button>
          <Button onClick={handleConfirmCreate}>確認する</Button>
          <Button
            disabled={!requestFormInfo?.generatedPdf}
            type={
              !requestFormInfo?.generatedPdf ? "disabled" : "primary"
            }
            onClick={handleSubmitCreate}
          >
            提出する
          </Button>
        </div>
      </div>

      <PageLoadingBackdrop open={isCreating} />
    </>
  );
}

function CreateOrderContent({ testScheduleId, quotationInfoId, caseId }) {
  const [loadInit, setLoadInit] = useState({
    isLoading: true,
    data: null,
    error: null,
  });

  const loadInitRequestForm = useCallback(() => {
    setLoadInit({
      isLoading: true,
      data: null,
      error: null,
    });

    orderServices
      .getInitRequestFormByQuotationInfoId(quotationInfoId)
      .then((resInit) => {
        setLoadInit({
          isLoading: false,
          data: {
            initRequestForm: resInit.data,
          },
          error: null,
        });
      })
      .catch((err) => {
        console.log("erorr: ", err);
        toast.error(messages.callApiFail);
        setLoadInit({
          isLoading: false,
          data: null,
          error: err,
        });
      });
  }, [quotationInfoId]);

  useEffect(() => {
    loadInitRequestForm();
  }, [loadInitRequestForm]);

  if (loadInit.isLoading) return <PageLoading />;
  if (loadInit.error) return <PageError />;

  if (loadInit.data) {
    if (loadInit.data.initRequestForm)
      return (
        <CreateOrderForm
          initOrderInfo={loadInit.data.initRequestForm}
          testScheduleId={testScheduleId}
          caseId={caseId}
        />
      );

    return <PageNoData />;
  }

  return null;
}

function CreateOrder() {
  const [selectedTestSchedule, setSelectedTestSchedule] =
    useState(null);
  const [selectedCaseInfo, setSelectedCaseInfo] = useState(null);

  const [searchState, setSearchState] = useState({
    isLoading: false,
    testScheduleId: "",
    searchResult: null,
    error: null,
  });

  const reset = () => {
    setSelectedCaseInfo(null);
    setSearchState({
      isLoading: false,
      testScheduleId: "",
      searchResult: null,
      error: null,
    });
  };

  const handleSearch = () => {
    if (
      searchState?.isLoading ||
      !selectedTestSchedule ||
      !selectedTestSchedule?.id ||
      !selectedCaseInfo
    )
      return;

    setSearchState({
      isLoading: true,
      testScheduleId: selectedTestSchedule?.id,
      searchResult: null,
      error: null,
    });

    orderServices
      .getByCaseId(selectedCaseInfo?._id)
      .then((res) => {
        setSearchState((oldState) => ({
          ...oldState,
          isLoading: false,
          searchResult: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setSearchState((oldState) => ({
          ...oldState,
          isLoading: false,
          searchResult: null,
          error: error,
        }));
      });
  };

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.order, linkTitle: "発注書の検索" },
        {
          linkTitle: "発注書の修正",
          isLabel: true,
        },
        {
          linkTo: routePaths.orderCreate,
          linkTitle: "発注書の作成",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
    >
      <div className="w-full h-auto">
        <div className="w-full h-auto mb-7 text-custom-size">
          <p>発注書（依頼書）の作成</p>
        </div>

        <div className="w-full h-auto flex items-end mb-12 flex-wrap">
          <div className="w-full mb-3 md:mb-0 md:w-auto flex-grow">
            <SelectTestScheduleAndCaseId
              schedule={selectedTestSchedule}
              setSchedule={(value) => {
                reset();
                setSelectedTestSchedule(value);
              }}
              caseInfo={selectedCaseInfo}
              setCaseInfo={(value) => {
                setSearchState({
                  isLoading: false,
                  testScheduleId: "",
                  searchResult: null,
                  error: null,
                });
                setSelectedCaseInfo(value);
              }}
              labelWidth={180}
            />
          </div>
          <div className="w-full md:w-auto shrink-0 pl-12 flex justify-end">
            <Button
              onClick={handleSearch}
              disabled={!selectedCaseInfo}
              type={!selectedCaseInfo ? "disabled" : "primary"}
            >
              呼び出す
            </Button>
          </div>
        </div>

        {searchState.testScheduleId ? (
          <>
            {searchState.isLoading ? <PageLoading /> : null}

            {!searchState.isLoading && searchState.error ? (
              <PageError />
            ) : null}

            {!searchState.isLoading &&
            !searchState.error &&
            searchState.searchResult ? (
              <div className="w-full h-full flex flex-col items-center justify-center py-12">
                <p className="text-xl font-semibold text-gray-600 mb-6 text-center">
                  <p>試験スケジュールは既に作成しました。</p>
                  <p>
                    修正必要な場合は、修正ページへ移動してください。
                  </p>
                </p>
              </div>
            ) : (
              <CreateOrderContent
                testScheduleId={searchState.testScheduleId}
                caseId={selectedCaseInfo._id}
                quotationInfoId={selectedCaseInfo.quotation}
              />
            )}
          </>
        ) : null}
        <div></div>
      </div>
    </ContentLayout>
  );
}

export default CreateOrder;
