const routePaths = {
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-assword",

  topMenu: "/top-menu",

  /* -------------------------------------------- */
  /*****     Dashboard (1. ダッシュボード)    *****/
  dashboard: "/dashboard",

  /*****     Order (2. 発注書の管理)    *****/
  order: "/order",
  orderUpdate: (id, caseId) =>
    id !== undefined
      ? `/order/update/${id}?caseId=${caseId}`
      : "/order/update/:id",
  orderCreate: "/order/create",

  /*****      (3. 試験サンプル発送管理)    *****/

  /*****     Chat (4. フェイス社への連絡)    *****/

  /*****     System Settings (5. システム設定)    *****/
  systemSettings: "/system-settings",
  updateSystemSettings: "/system-settings/update",

  chat: "/chat",
};

export default routePaths;
