import React from "react";
import SelectOption from "./components/SelectOption";

const colatileComponentOptions = [
  {
    value: "含む",
    label: "含む",
  },
  {
    value: "含まない",
    label: "含まない",
  },
];

function SelectVolatileComponents(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full flex flex-col items-center justify-center gap-2.5 p-2">
        <SelectOption
          label={colatileComponentOptions[0].label}
          value={colatileComponentOptions[0].value}
          onSelect={onSelect}
          isSelected={selectedValue === colatileComponentOptions[0].value}
          isReadonly={isReadonly}
        />
        <SelectOption
          label={colatileComponentOptions[1].label}
          value={colatileComponentOptions[1].value}
          onSelect={onSelect}
          isSelected={selectedValue === colatileComponentOptions[1].value}
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

export default SelectVolatileComponents;
