import { io } from "socket.io-client";
const URL = process.env.REACT_APP_SOCKET_URL;

export const socketConfig = () => {
  const localTk = localStorage.getItem("persist:root");
  if (localTk) {
    const token = JSON?.parse(JSON?.parse(localTk)?.auth)?.token?.accessToken;
    return io(URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
};

export const getCurrentCustomerInfoId = () => {
  const localTk = localStorage?.getItem("persist:root");
  return JSON.parse(JSON.parse(localTk)?.auth)?.user?.id;
};

export const getCurrentUserType = () => {
  const localTk = localStorage?.getItem("persist:root");
  return JSON.parse(JSON.parse(localTk)?.auth)?.user?.accountType;
};
