import React from "react";
import SelectOption from "./components/SelectOption";
import SelectOptionOther from "./components/SelectOptionOther";

function SelectCosmeticUsage({
  selectedValue,
  onSelect,
  isReadonly,
  screenWidth,
}) {
  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div>
        <div className="w-full h-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-center justify-center gap-2.5 py-2 px-2 lg:px-[30px]">
          <SelectOption
            label="洗顔料"
            value="洗顔料"
            onSelect={onSelect}
            isSelected={selectedValue === "洗顔料"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label={
              <div className="w-full h-full flex flex-col items-center justify-center text-[10px] leading-3">
                <p>クレンジング</p>
                <p>洗い流し</p>
              </div>
            }
            value="クレンジング 洗い流し"
            onSelect={onSelect}
            isSelected={selectedValue === "クレンジング 洗い流し"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label={
              <div className="w-full h-full flex flex-col items-center justify-center text-[10px] leading-3">
                <p>クレンジング</p>
                <p>ふき取り</p>
              </div>
            }
            value="クレンジング ふき取り"
            onSelect={onSelect}
            isSelected={selectedValue === "クレンジング ふき取り"}
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label="化粧水"
            value="化粧水"
            onSelect={onSelect}
            isSelected={selectedValue === "化粧水"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="美容液"
            value="美容液"
            onSelect={onSelect}
            isSelected={selectedValue === "美容液"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="ジェル"
            value="ジェル"
            onSelect={onSelect}
            isSelected={selectedValue === "ジェル"}
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label="オイル"
            value="オイル"
            onSelect={onSelect}
            isSelected={selectedValue === "オイル"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="乳液"
            value="乳液"
            onSelect={onSelect}
            isSelected={selectedValue === "乳液"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="O/Wクリーム"
            value="O/Wクリーム"
            onSelect={onSelect}
            isSelected={selectedValue === "O/Wクリーム"}
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label="W/Oクリーム"
            value="W/Oクリーム"
            onSelect={onSelect}
            isSelected={selectedValue === "W/Oクリーム"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="メイク品"
            value="メイク品"
            onSelect={onSelect}
            isSelected={selectedValue === "メイク品"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="シャンプー"
            value="シャンプー"
            onSelect={onSelect}
            isSelected={selectedValue === "シャンプー"}
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label={
              <div className="w-full h-full flex flex-col items-center justify-center text-[10px] leading-3">
                <p>コンディショナー</p>
                <p>リンス</p>
              </div>
            }
            value="コンディショナー リンス"
            onSelect={onSelect}
            isSelected={selectedValue === "コンディショナー リンス"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="トリートメント"
            value="トリートメント"
            onSelect={onSelect}
            isSelected={selectedValue === "トリートメント"}
            isReadonly={isReadonly}
          />
          <SelectOption
            label="原料"
            value="原料"
            onSelect={onSelect}
            isSelected={selectedValue === "原料"}
            isReadonly={isReadonly}
          />
          {(screenWidth >= 1024 || screenWidth < 640) && (
            <div className=" h-0"></div>
          )}
          <SelectOptionOther
            selectedValue={selectedValue}
            onSelect={onSelect}
            isReadonly={isReadonly}
            otherPrefix="その他"
            inputWidth={screenWidth < 640 ? 1 : 2}
          />
        </div>
        <div className="p-2 self-start text-sm text-[#707070]">
          メイク品、その他の項目については詳細のご記載をお願いします
        </div>
      </div>
    </div>
  );
}

export default SelectCosmeticUsage;
