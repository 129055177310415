import client from "./client";

const getAll = () => {
  return client.get("/test-schedule-info/all").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result))
      return {
        data: res.data?.result.map((item) => ({
          id: item._id,
          testDate: item.testDate,
        })),
      };
    throw new Error("Malformed data!");
  });
};

const scheduleServices = {
  getAll,
};

export default scheduleServices;
