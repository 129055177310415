import React, { useState } from "react";
import ContentLayout from "app/layouts/ContentLayout";
import routePaths from "app/route/routePaths";
import Button from "app/components/UI/Button";
import SelectTestScheduleAndCaseId from "app/components/UI/Form/Select/SelectTestScheduleAndCaseId";
import { orderServices } from "app/services/api";
import { toast } from "react-toastify";
import PageLoading from "app/components/CommonPage/PageLoading";
import PageError from "app/components/CommonPage/PageError";
import PageNoData from "app/components/CommonPage/PageNoData";
import TestMaterialInfoTableForm from "app/components/Table/Order/TestMaterialInfoTableForm";
import OrderSumaryUpdateTable from "app/components/Table/Order/OrderSumaryUpdateTable";
import { Link, useOutletContext } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import messages from "../../../services/api/messages";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateTestContentInfoForm(props) {
  const { testContentInfoItem, requestFormInfo } = props;

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-10">
        <OrderSumaryUpdateTable
          testContentInfoItem={testContentInfoItem}
          isReadonly={true}
          requestFormInfo={requestFormInfo}
        />
      </div>

      {Array.isArray(testContentInfoItem?.testMaterialInfos) &&
      testContentInfoItem.testMaterialInfos.length > 0 ? (
        testContentInfoItem.testMaterialInfos.map((item, index) => (
          <TestMaterialInfoTableForm
            testMaterialNo={index + 1}
            testMaterialInfo={item}
            isReadonly={true}
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="w-full h-full">{children}</div>
      )}
    </div>
  );
}

function SearchOrderResult({ orderInfo, caseInfo }) {
  const [showTab, setShowTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setShowTab(newValue);
  };

  return (
    <div className="w-full h-auto">
      <h3 className="text-custom-size mb-7">検索結果</h3>

      <div className="w-full h-auto flex items-center justify-between mb-4">
        <h3 className="text-custom-size">試験内容</h3>
        <Link
          to={routePaths.orderUpdate(orderInfo?.id, caseInfo?._id)}
          className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#4F1C00] text-white"
        >
          修正する
        </Link>
      </div>

      <div className="w-full">
        <div className="border-b w-full overflow-x-auto">
          <Tabs
            value={showTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            className="mb-3"
            variant="scrollable"
            scrollButtons="auto"
          >
            {orderInfo.testContentInfos.map(
              (testContentInfoItem, index) => {
                return testContentInfoItem?.testMaterialInfos
                  ?.length > 0 ? (
                  <Tab
                    label={
                      testContentInfoItem?.testItemName ||
                      `Tab ${index + 1}`
                    }
                    {...a11yProps(index)}
                  />
                ) : null;
              }
            )}
          </Tabs>
        </div>

        {orderInfo.testContentInfos.map(
          (testContentInfoItem, index) => {
            return testContentInfoItem?.testMaterialInfos?.length >
              0 ? (
              <CustomTabPanel value={showTab} index={index}>
                <CreateTestContentInfoForm
                  testContentInfoItem={testContentInfoItem}
                  requestFormInfo={orderInfo}
                />
              </CustomTabPanel>
            ) : null;
          }
        )}
      </div>
    </div>
  );
}

function SearchOrderContent(props) {
  const [selectedTestSchedule, setSelectedTestSchedule] =
    useState(null);
  const [selectedCaseInfo, setSelectedCaseInfo] = useState(null);

  const [searchState, setSearchState] = useState({
    isLoading: false,
    testScheduleId: "",
    searchResult: null,
    error: null,
  });

  const reset = () => {
    setSelectedCaseInfo(null);
    setSearchState({
      isLoading: false,
      testScheduleId: "",
      searchResult: null,
      error: null,
    });
  };

  const handleSearch = () => {
    if (
      searchState?.isLoading ||
      !selectedTestSchedule ||
      !selectedTestSchedule?.id ||
      !selectedCaseInfo
    )
      return;

    setSearchState({
      isLoading: true,
      testScheduleId: selectedTestSchedule?.id,
      searchResult: null,
      error: null,
    });

    orderServices
      .getByCaseId(selectedCaseInfo?._id)
      .then((res) => {
        setSearchState((oldState) => ({
          ...oldState,
          isLoading: false,
          searchResult: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setSearchState((oldState) => ({
          ...oldState,
          isLoading: false,
          searchResult: null,
          error: error,
        }));
      });
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto mb-7 text-custom-size">
        <p>発注書（依頼書）の検索を行うことができます。</p>
        <p>試験スケジュールを選択して検索するを押してください。</p>
      </div>

      <div className="w-full h-auto flex items-end mb-12 flex-wrap justify-between">
        <div className="w-full mb-3 md:mb-0 md:w-auto flex-grow">
          <SelectTestScheduleAndCaseId
            schedule={selectedTestSchedule}
            setSchedule={(value) => {
              reset();
              setSelectedTestSchedule(value);
            }}
            caseInfo={selectedCaseInfo}
            setCaseInfo={(value) => {
              setSearchState({
                isLoading: false,
                testScheduleId: "",
                searchResult: null,
                error: null,
              });
              setSelectedCaseInfo(value);
            }}
            labelWidth={160}
          />
        </div>
        <div className="w-full md:w-auto shrink-0 pl-12 flex justify-end">
          <Button
            onClick={handleSearch}
            disabled={
              !selectedTestSchedule || !selectedTestSchedule?.id || !selectedCaseInfo?._id
            }
            type={!selectedCaseInfo ? "disabled" : "primary"}
          >
            検索する
          </Button>
        </div>
      </div>

      {searchState.testScheduleId ? (
        <>
          {searchState.isLoading ? <PageLoading /> : null}
          {!searchState.isLoading && searchState.error ? (
            <PageError />
          ) : null}

          {!searchState.isLoading &&
          !searchState.error &&
          searchState.searchResult ? (
            searchState.searchResult?.id ? (
              <SearchOrderResult
                orderInfo={searchState.searchResult}
                caseInfo={selectedCaseInfo}
              />
            ) : (
              <PageNoData />
            )
          ) : (
            <PageNoData />
          )}
        </>
      ) : null}
      <div></div>
    </div>
  );
}

function SearchOrder() {
  const [breadcrumb] = useOutletContext();
  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.order, linkTitle: "発注書の検索" },
        {
          isLabel: true,
          linkTitle: "発注書の修正",
        },
        {
          linkTo: routePaths.orderCreate,
          linkTitle: "発注書の作成",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
      breadcrumb={breadcrumb}
    >
      <SearchOrderContent />
    </ContentLayout>
  );
}

export default SearchOrder;
