import React from "react";
import SelectOption from "./components/SelectOption";

const classificationTypeOptions = [
  {
    value: "leanOn",
    label: "リーンオン"
  },
  {
    value: "leanOff",
    label: "リーンオフ"
  },
]

function SelectClassification(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full flex flex-col items-center justify-center gap-2.5 p-2">
      <SelectOption
          label={classificationTypeOptions[0].label}
          value={classificationTypeOptions[0].value}
          onSelect={onSelect}
          isSelected={selectedValue === classificationTypeOptions[0].value}
          isReadonly={isReadonly}
        />
        <SelectOption
          label={classificationTypeOptions[1].label}
          value={classificationTypeOptions[1].value}
          onSelect={onSelect}
          isSelected={selectedValue === classificationTypeOptions[1].value}
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

export default SelectClassification;
