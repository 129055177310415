import client from "./client";
import customerServices from "./customerServices";
import scheduleServices from "./scheduleServices";
import orderServices from "./orderServices";
import projectServices from "./projectServices";
import axios from "axios";
import store from "../../redux/store";

const verifyToken = async (callbackFailed) => {
  return client
    .get('/verify-token')
    .then((res) => console.log("VERIFY TOKEN SUCCESS RES", res))
    .catch((err) => {
      console.log("VERIFY TOKEN FAILED ERR", err);
      typeof callbackFailed === "function" && callbackFailed();
    });
};

const login = (email, password) => {
  return client.post("/login", {
    loginName: email,
    password,
    type: "customer",
  });
};

export const getAllUserId = () => {
  return client.get("/user/info/all-user-info").then((res) => {
    if (res.data?.success && Array.isArray(res.data?.result))
      return {
        data: res.data.result.map((item) => ({
          id: item?._id,
          lastNameKanji: item?.lastNameKanji,
          firstNameKanji: item?.firstNameKanji,
        })),
      };
    throw new Error("Malformed data!");
  });
};

const downloadWithFileId = async (id, fileType, filename = "filename") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient
    .get(`/download/download-with-file-id?fileId=${id}`)
    .then((res) => {
      if (!res.data || res.data.length === 0 || !!res.headers.error) return res;
      var mimeType = "application/octet-stream";
      const name = `${filename}.${fileType}`;
      var blob = new Blob([res.data], { type: mimeType });

      var a = document.createElement("a");
      a.download = name;
      a.target = "_blank";

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name);
      } else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      } else {
        // for Safari
        window.open(
          "data:" + mimeType + ";base64," + window.btoa(res.data),
          "_blank"
        );
      }
    });
};

const downloadPDFWithFileId = async (id, filename = "filename") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient
    .get(`/download/download-pdf-with-file-id?fileId=${id}`)
    .then((res) => {
      if (!res.data || res.data.length === 0 || !!res.headers.error) return res;
      var mimeType = "application/octet-stream";
      const name = `${filename}.pdf`;
      var blob = new Blob([res.data], { type: mimeType });

      var a = document.createElement("a");
      a.download = name;
      a.target = "_blank";

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name);
      } else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      } else {
        // for Safari
        window.open(
          "data:" + mimeType + ";base64," + window.btoa(res.data),
          "_blank"
        );
      }
    });
};

const downloadWithFilePath = (path, fileType = "pdf") => {
  const downloadClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    responseType: "arraybuffer",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: store.getState()?.auth.token?.accessToken || "",
    },
  });
  return downloadClient.get("/download/buffer", {
    params: {
      path,
    },
  });
};

const updateContractProgress = (contractId, newProgress) => {
  console.log("contractId: ", contractId);
  console.log("newProgress: ", newProgress);
  return client.put("/contract-info/update-contract-progress", {
    id: contractId,
    progress: newProgress,
  });
};

export const getAllAccountList = () => {
  return client.get("/user/account/all");
};

const authServices = {
  login,
};

const contractServices = {
  updateContractProgress,
};

const commonServices = {
  verifyToken,
  getAllUserId,
  getAllAccountList,
  downloadWithFileId,
  downloadWithFilePath,
  downloadPDFWithFileId,
};

export {
  authServices,
  customerServices,
  scheduleServices,
  orderServices,
  commonServices,
  projectServices,
  contractServices,
};
