import client from "./client";

const getScheduleList = async () => {
  return client.get("/test-schedule-info/all").then((res) => {
    if (res.data?.success && res.data?.result) {
      return {
        scheduleList: res.data.result.map((i) => ({ ...i, id: i._id })),
        caseList: res.data.result.map((i) => ({
          testDate: i.testDate,
          caseInfos: i.caseInfos,
          caseId: i._id,
        })),
      };
    }
    throw new Error("Malformed data!");
  });
};

const updateTestSchedule = async (body) => {
  const sendData = body.map((i) => ({
    id: i._id,
    testDate: i.testDate,
    reportDeliveryDate: i.reportDeliveryDate,
    samplePaymentDeadline: i.samplePaymentDeadline,
    applicationDeadline: i.applicationDeadline,
    possibleApplicationNumber: i.possibleApplicationNumber,
    arrivedSampleNumber: i.arrivedSampleNumber,
    undeliveredSampleNumber: i.undeliveredSampleNumber,
  }));
  return client.post("/test-schedule-info/create-many", sendData);
};

const updateStatus = ({ id, statusType, value }) => {
  const body = {
    [statusType]: value,
  };
  return client.put(`/case-info/update-status?id=${id}`, body);
};

const updateCaseStatusComplete = (id) => {
  return client.put(`/case-info/update-status-complete?id=${id}`);
};

const projectServices = {
  getScheduleList,
  updateTestSchedule,
  updateStatus,
  updateCaseStatusComplete
};

export default projectServices;
