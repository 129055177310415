import React from "react";

function SelectOption(props) {
  const { label, value, onSelect, isSelected, width, isReadonly } = props;

  const handleClick = () => {
    if(isReadonly) return;
    onSelect(value);
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`h-[30px] flex items-center justify-center text-[10px] leading-3 font-noto-sans-jp rounded-full px-2 text-white ${
        isSelected ? "bg-[#dc5553]" : "bg-[#eda9a9]"
      } ${isReadonly ? "cursor-default" : "cursor-pointer"}`}
      style={{
        width: width || "100%",
      }}
      disabled={isReadonly}
    >
      {label}
    </button>
  );
}

export default SelectOption;
