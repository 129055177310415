// FE-1
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ContentLayout from "app/layouts/ContentLayout";
import PageError from "app/components/CommonPage/PageError";
import PageLoading from "app/components/CommonPage/PageLoading";

import CustomerDetailBaseInfo from "app/components/Table/Customer/CustomerDetailBaseInfo";
import CustomerPICDetail from "app/components/Table/Customer/CustomerPICDetail";
import CustomerBillingInfoDetail from "app/components/Table/Customer/CustomerBillingInfoDetail";

import { customerServices } from "app/services/api";
import routePaths from "app/route/routePaths";
import messages from "../../../services/api/messages";

function DetailSystemSettingsContent(props) {
  const { customerBaseInfo } = props;

  return (
    <>
      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px] flex items-center justify-between">
          <h3 className="text-custom-size">自社情報</h3>

          <div className="flex items-center gap-4">
            <Link
              to={routePaths.updateSystemSettings}
              className="rounded-[10px] px-[1.375em] py-[0.75em] text-[0.8125em] bg-[#4F1C00] text-white"
            >
              変更する
            </Link>
          </div>
        </div>
        <CustomerDetailBaseInfo customerBaseInfo={customerBaseInfo} />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="custom-size">担当者情報</h3>
        </div>
        <CustomerPICDetail picInfo={customerBaseInfo.pic} />
      </div>

      <div className="w-full h-auto mb-6">
        <div className="w-full h-auto pt-0 pb-[17px]">
          <h3 className="text-custom-size">請求先情報</h3>
        </div>
        <CustomerBillingInfoDetail billingInfo={customerBaseInfo.billing} />
      </div>
    </>
  );
}

function DetailSystemSettings() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getDetail()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.systemSettings, linkTitle: "自社情報の確認" },
        {
          linkTo: routePaths.updateSystemSettings,
          linkTitle: "自社情報の変更",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
    >
      <div className="w-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <DetailSystemSettingsContent
            customerBaseInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </ContentLayout>
  );
}

export default DetailSystemSettings;
