import { caseStatus, quotationStatus, reportStatus, requestFormStatus, testPlanStatus, DEFAULT_JP_DATE_FORMAT } from "app/constants";
import moment from "moment";

export const defaultFormatCurrency = (amount) => {
  if (Number.isNaN(amount)) return "";
  return `${new Intl.NumberFormat().format(amount)}円`;
};

export const defaultFormatNumberOfPeople = (numberOfPeople) => {
  if (Number.isNaN(numberOfPeople)) return "";
  return `${Number(numberOfPeople)}名`;
};

export const defaultFormatNumberOfSample = (numberOfSample) => {
  if (Number.isNaN(numberOfSample)) return "";
  return `${Number(numberOfSample)}個`;
};

export const getNameKanji = (nameKanji) => {
  const { lastNameKanji, firstNameKanji } = nameKanji || {};
  return `${lastNameKanji || ""} ${firstNameKanji || ""}`;
};

export const getNameKana = (nameKana) => {
  const { lastNameKana, firstNameKana } = nameKana;
  return `${lastNameKana || ""} ${firstNameKana || ""}`;
};

export const extractFirstLastFromFullName = (fullName) => {
  if (typeof fullName !== "string")
    return {
      firstName: "",
      lastName: "",
    };
  if (fullName.includes(" ")) {
    const sliceIndex = fullName.indexOf(" ");

    return {
      firstName: fullName.slice(0, sliceIndex).trim(),
      lastName: fullName.slice(sliceIndex + 1).trim(),
    };
  }
  if (fullName.includes("　")) {
    const sliceIndex = fullName.indexOf("　");

    return {
      firstName: fullName.slice(0, sliceIndex).trim(),
      lastName: fullName.slice(sliceIndex + 1).trim(),
    };
  }

  return {
    firstName: fullName,
    lastName: "",
  };
};

export const getCaseStatus = (rowData) => {
  const { quotation, requestForm, testPlan, testSample, report, invoice } =
    rowData;

  if (quotation === quotationStatus.submitted) return caseStatus.quotation;
  else if (quotation === quotationStatus.lost) return caseStatus.lost;

  if (requestForm === requestFormStatus.confirmed || requestForm === requestFormStatus.submitted) return caseStatus.requestForm;

  if (testPlan === testPlanStatus.submitted) return caseStatus.testPlan;
  if (reportStatus.submitted) return "";
};

export const getAddressFromComponent = (addressComponent) => {
  const { postalCode = "", prefecture = "", address1 = "", address2 = "", address3 = "" } = addressComponent || {};
  return `${postalCode ? `〒${postalCode} ` : ""}${prefecture}${address1}${address2}${address3}`;
};

export const convertDateStringToJpFormat = (dateStr) => {
  if (!dateStr || typeof dateStr !== "string") return moment();
  return moment(dateStr).format(DEFAULT_JP_DATE_FORMAT);
};

export const handleDownloadResponse = (response, fileType, fileName = "filename") => {
  if (!response.data || response.data.length === 0 || !!response.headers.error) return response;
  var mimeType = "application/octet-stream";
  const name = `${fileName}.${fileType}`;
  var blob = new Blob([response.data], { type: mimeType });

  var a = document.createElement("a");
  a.download = name;
  a.target = "_blank";

  if (window.navigator.msSaveBlob) {
    // for IE
    window.navigator.msSaveBlob(blob, name);
  } else if (window.URL && window.URL.createObjectURL) {
    // for Firefox
    a.href = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else if (window.webkitURL && window.webkitURL.createObject) {
    // for Chrome
    a.href = window.webkitURL.createObjectURL(blob);
    a.click();
  } else {
    // for Safari
    window.open("data:" + mimeType + ";base64," + window.btoa(response.data), "_blank");
  }
};

export const windowShowNotification = (msg) => {
  const askReq = () => {
    Notification.requestPermission((permission) => {
      if (permission === "granted" && msg) {
        new Notification(msg);
      }
    });
  };
  if (!("Notification" in window)) {
    alert("This browser does not support notification");
  } else if (Notification.permission == "granted" && msg) {
    new Notification(msg);
  } else if (Notification.permission == "denied" || Notification.permission == "default") {
    askReq();
  }
};

export const urlBase64ToUint8Array = (base64String) => {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
