// FE-1
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ContentLayout from "app/layouts/ContentLayout";
import PageLoading from "app/components/CommonPage/PageLoading";
import InputText from "app/components/UI/Form/Input/InputText";
import PageError from "app/components/CommonPage/PageError";
import ConfirmDeleteDialog from "app/components/Dialog/ConfirmDeleteDialog";
import Button from "app/components/UI/Button";
import routePaths from "app/route/routePaths";
import { callApiStatus } from "app/constants";
import { customerServices } from "app/services/api";
import TrashBinSVG from "app/assets/svg/trashbin.svg";

import CustomerInfoForm from "./CustomerInfoForm";
import messages from "../../../services/api/messages";

const labelWidth = 146;

function UpdateSystemSettingsPageContent(props) {
  const { customerBaseInfo } = props;

  const navigate = useNavigate();
  const formDataRef = useRef();
  const clickSubmit = () => {
    if (
      formDataRef.current &&
      typeof formDataRef.current.clickSubmit === "function"
    )
      formDataRef.current.clickSubmit();
  };

  const [updateStatus, setUpdateStatus] = useState(callApiStatus.idle);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleSubmitForm = (formData) => {
    const data = {
      customerId: customerBaseInfo.customerId,
      ...formData,
    };

    console.log("data: ", data);
    if (updateStatus === callApiStatus.loading) return;
    customerServices
      .updateInfo(data)
      .then((res) => {
        setUpdateStatus(callApiStatus.idle);
        navigate(routePaths.systemSettings);
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setUpdateStatus(callApiStatus.error);
      });
  };

  const handleDeleteCustomer = async () => {
    return await customerServices.deleteCustomer(customerBaseInfo.id);
  };

  return (
    <>
      <div className="pl-4">
        <div className="flex justify-between pb-4">
          <div className="flex items-end">顧客情報</div>
          <div className="flex gap-4">
            <Button onClick={clickSubmit}>変更する</Button>
          </div>
        </div>
        <div className="w-full h-auto">
          <div className="mb">
            <InputText
              id="customerId"
              value={customerBaseInfo.customerId || ""}
              label="お客様ID"
              labelWidth={labelWidth}
              disabled={true}
            />
            <InputText
              id="customerId"
              value={customerBaseInfo.companyNameKanji || ""}
              label="会社名"
              labelWidth={labelWidth}
              disabled={true}
            />
            <InputText
              id="customerId"
              value={customerBaseInfo.companyNameKana || ""}
              label="フリガナ"
              labelWidth={labelWidth}
              disabled={true}
            />
          </div>

          <CustomerInfoForm
            ref={formDataRef}
            handleSubmitForm={handleSubmitForm}
            customerBaseInfo={customerBaseInfo}
          />
        </div>
      </div>

      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="Delete customer!"
        confirmMessage="Are you sure you want to delete this customer?"
        deleteCompleteTitle="Successfully deleted customer"
        deleteCompleteBtnLabel="顧客情報の検索に戻る"
        deleteCompleteAction={() => navigate(routePaths.systemSettingsCustomer)}
        deleteAction={handleDeleteCustomer}
        // handleDeleteSuccess
        // handleDeleteError
      />
    </>
  );
}

function UpdateSystemSettings(props) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInit = useCallback(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    customerServices
      .getDetail()
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: {
            ...res.data,
            quotationBasics: undefined,
            contracts: undefined,
          },
          error: null,
        });
      })
      .catch((error) => {
        toast.error(messages.callApiFail);
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, []);

  useEffect(() => {
    loadInit();
  }, [loadInit]);

  return (
    <ContentLayout
      sideMenuLinkOptions={[
        { linkTo: routePaths.systemSettings, linkTitle: "自社情報の確認" },
        {
          linkTo: routePaths.updateSystemSettings,
          linkTitle: "自社情報の変更",
        },
        { linkTo: routePaths.topMenu, linkTitle: "トップへ戻る" },
      ]}
    >
      <div className="w-full h-full grow flex flex-col pt-2.5">
        {loadInitState.isLoading ? <PageLoading /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <PageError /> : null}

        {!loadInitState.isLoading &&
        !loadInitState.error &&
        loadInitState.data ? (
          <UpdateSystemSettingsPageContent
            customerBaseInfo={loadInitState.data}
          />
        ) : null}
      </div>
    </ContentLayout>
  );
}

export default UpdateSystemSettings;
