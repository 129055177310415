import React, { useMemo } from "react";
import SelectOption from "./components/SelectOption";

// originalMaterialExperienceOptions
const OMEOptions = [
  {
    value: "国内販売実績のある成分のみを使用している",
    label: "国内販売実績のある成分のみを使用している",
    subOptions: [
      {
        value: "ネガティブリスト収載成分は使用していない",
        label: "ネガティブリスト収載成分は使用していない",
      },
      {
        value: "ポジティブリスト収載成分は使用していない",
        label: "ポジティブリスト収載成分は使用していない",
      },
      {
        value: "ポジティブリスト収載成分を最大配合量未満で使用している",
        label: "ポジティブリスト収載成分を最大配合量未満で使用している",
      },
    ],
  },
  {
    value: "国内市販実績のない新規成分を使用している",
    label: "国内市販実績のない新規成分を使用している",
  },
  {
    value: "国内で実績のある濃度以上で使用している成分がある",
    label: "国内で実績のある濃度以上で使用している成分がある",
  },
  {
    value: "ヒト由来の原料を使用している",
    label: "ヒト由来の原料を使用している",
  },
];

function SelectMultiSubOption(props) {
  const { selectedSubValues, onSelect, handleSelect, isReadonly } = props;

  const isSelectedOption = useMemo(() => {
    return selectedSubValues.find(
      (item) =>
        item === OMEOptions[0].subOptions[0].value ||
        item === OMEOptions[0].subOptions[1].value ||
        item === OMEOptions[0].subOptions[2].value
    );
  }, [selectedSubValues]);

  const handleSelectOption = (value) => {
    if (!isSelectedOption) {
      onSelect(
        [
          ...selectedSubValues,
          OMEOptions[0].subOptions[0].value,
          OMEOptions[0].subOptions[1].value,
          OMEOptions[0].subOptions[2].value,
        ].join("|")
      );
    } else {
      onSelect(
        selectedSubValues
          .filter(
            (item) =>
              ![
                OMEOptions[0].subOptions[0].value,
                OMEOptions[0].subOptions[1].value,
                OMEOptions[0].subOptions[2].value,
              ].includes(item)
          )
          .join("|")
      );
    }
  };

  const handleSelectSubOption = (subValue) => {
    handleSelect(subValue);
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-full flex flex-col items-center justify-center gap-2.5 relative">
        <SelectOption
          label={
            <p className="w-full text-start px-3">{OMEOptions[0].label}</p>
          }
          value={OMEOptions[0].value}
          onSelect={handleSelectOption}
          isSelected={isSelectedOption}
          width="100%"
          isReadonly={isReadonly}
          className="text-start"
        />
        <div className="w-full h-auto flex items-center pl-[30px]">
          <div className="w-[22px] h-full flex items-center">
            <div className="w-full h-[2px] bg-[#6C6C6C]"></div>
            <div className="w-0 h-0 border-t-4 border-t-transparent  border-b-4 border-b-transparent border-l-4 border-l-[#6C6C6C]"></div>
          </div>
          <div className="w-[calc(100%-22px)]">
            <SelectOption
              label={
                <p className="w-full text-start px-3">
                  {OMEOptions[0].subOptions[0].label}
                </p>
              }
              value={OMEOptions[0].subOptions[0].value}
              onSelect={handleSelectSubOption}
              isSelected={selectedSubValues.includes(
                OMEOptions[0].subOptions[0].value
              )}
              width="100%"
              isReadonly={isReadonly}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center pl-[30px]">
          <div className="w-[22px] h-full flex items-center">
            <div className="w-full h-[2px] bg-[#6C6C6C]"></div>
            <div className="w-0 h-0 border-t-4 border-t-transparent  border-b-4 border-b-transparent border-l-4 border-l-[#6C6C6C]"></div>
          </div>
          <div className="w-[calc(100%-22px)]">
            <SelectOption
              label={
                <p className="w-full text-start px-3">
                  {OMEOptions[0].subOptions[1].label}
                </p>
              }
              value={OMEOptions[0].subOptions[1].value}
              onSelect={handleSelectSubOption}
              isSelected={selectedSubValues.includes(
                OMEOptions[0].subOptions[1].value
              )}
              width="100%"
              isReadonly={isReadonly}
            />
          </div>
        </div>

        <div className="w-full h-auto flex items-center pl-[30px]">
          <div className="w-[22px] h-full flex items-center">
            <div className="w-full h-[2px] bg-[#6C6C6C]"></div>
            <div className="w-0 h-0 border-t-4 border-t-transparent  border-b-4 border-b-transparent border-l-4 border-l-[#6C6C6C]"></div>
          </div>
          <div className="w-[calc(100%-22px)]">
            <SelectOption
              label={
                <p className="w-full text-start px-3">
                  {OMEOptions[0].subOptions[2].label}
                </p>
              }
              value={OMEOptions[0].subOptions[2].value}
              onSelect={handleSelectSubOption}
              isSelected={selectedSubValues.includes(
                OMEOptions[0].subOptions[2].value
              )}
              width="100%"
              isReadonly={isReadonly}
            />
          </div>
        </div>

        <div className="absolute top-[30px] left-[30px] bottom-[14px] w-0.5 bg-[#6C6C6C]"></div>
      </div>
    </div>
  );
}

function SelectOriginalMaterialExperience(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  const selectedSubValues = useMemo(() => {
    if (!selectedValue || typeof selectedValue !== "string") return [];

    return selectedValue.split("|").filter((item) => item);
  }, [selectedValue]);

  const handleSelect = (value) => {
    if (selectedSubValues.includes(value)) {
      onSelect(selectedSubValues.filter((item) => item !== value).join("|"));
    } else {
      onSelect([...selectedSubValues, value].join("|"));
    }
  };

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full flex flex-col items-center justify-center gap-2.5 px-[22px]">
        <SelectMultiSubOption
          selectedSubValues={selectedSubValues}
          onSelect={onSelect}
          handleSelect={handleSelect}
          isReadonly={isReadonly}
        />
        <SelectOption
          label={
            <p className="w-full text-start px-3">{OMEOptions[1].label}</p>
          }
          value={OMEOptions[1].value}
          onSelect={handleSelect}
          isSelected={selectedSubValues.includes(OMEOptions[1].value)}
          width="100%"
          isReadonly={isReadonly}
        />
        <SelectOption
          label={
            <p className="w-full text-start px-3">{OMEOptions[1].label}</p>
          }
          value={OMEOptions[2].value}
          onSelect={handleSelect}
          isSelected={selectedSubValues.includes(OMEOptions[2].value)}
          width="100%"
          isReadonly={isReadonly}
        />
        <SelectOption
          label={
            <p className="w-full text-start px-3">{OMEOptions[3].label}</p>
          }
          value={OMEOptions[3].value}
          onSelect={handleSelect}
          isSelected={selectedSubValues.includes(OMEOptions[3].value)}
          width="100%"
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

export default SelectOriginalMaterialExperience;
