import React from "react";

function QuotationPriceCell(props) {
  const { width, caseInfo } = props;
  return (
    <div
      className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
      style={{
        width: width,
      }}
    >
      <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
        {`${caseInfo?.quotationPrice || 0}円`}
      </p>
    </div>
  );
}

export default QuotationPriceCell;
