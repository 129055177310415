import React, { useMemo } from "react";
import TableInfoHeader from "../../CommonComponents/TableInfo/TableInfoHeader";
import TableInfoRow from "../../CommonComponents/TableInfo/TableInfoRow";
import SelectPopover from "../../../UI/Form/Select/SelectPopover";

const tableConfig = [
  {
    title: "項目",
    width: "25%",
    dataKey: "title",
  },
  {
    title: "詳細",
    width: "75%",
    dataKey: "value",
  },
];

const planPresentationOptions = [
  {
    value: "required",
    label: "必要",
  },
  {
    value: "notRequired",
    label: "不要",
  },
];

const returnTestMaterialOptions = [
  {
    value: "required",
    label: "必要",
  },
  {
    value: "notRequired",
    label: "不要",
  },
];

function TestContentInfoTable(props) {
  const {
    testContentInfoItem,
    isReadonly,
    requestFormInfo,
    setRequestFormInfo,
  } = props;

  const data = useMemo(() => {
    return {
      testItemName: testContentInfoItem?.testItemName || "",
      testProductNumber: `${
        testContentInfoItem?.testProductNumber || 0
      }個`,
      testPeopleNumber: `${
        testContentInfoItem?.testPeopleNumber || 0
      }人`,
    };
  }, [testContentInfoItem]);

  const getSelectedLabel = (options, key) => {
    return options?.find((item) => item?.value === key)?.label || "";
  };

  return (
    <div className="w-full h-full overflow-auto relative">
      <TableInfoHeader tableConfig={tableConfig} />

      <TableInfoRow
        tableConfig={tableConfig}
        rowData={{
          title: "試験項目",
          value: data?.testItemName,
        }}
        className="bg-[#ebebeb]"
      />
      <TableInfoRow
        tableConfig={tableConfig}
        rowData={{
          title: "被験品数",
          value: data?.testProductNumber,
        }}
        className="bg-[#ebebeb]"
      />
      <TableInfoRow
        tableConfig={tableConfig}
        rowData={{
          title: "被験者数",
          value: data?.testPeopleNumber,
        }}
        className="bg-[#ebebeb]"
      />

      <div className="w-full h-[52px] flex">
        <div
          className="h-full border border-solid border-[#B3B3B3] px-2 py-4 bg-[#ebebeb]"
          style={{
            width: "25%",
            textAlign: "start",
          }}
        >
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            計画書の提示
          </p>
        </div>

        <div
          className={`h-full ${
            isReadonly
              ? "border border-solid border-[#B3B3B3] px-2 py-4 bg-[#ebebeb]"
              : ""
          }`}
          style={{
            width: "75%",
            textAlign: "start",
          }}
        >
          {isReadonly ? (
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {getSelectedLabel(
                planPresentationOptions,
                requestFormInfo?.planPresentation
              )}
            </p>
          ) : (
            <SelectPopover
              selectedValue={requestFormInfo?.planPresentation}
              setSelectedValue={(newValue) =>
                setRequestFormInfo({
                  ...requestFormInfo,
                  planPresentation: newValue,
                })
              }
              options={planPresentationOptions}
            />
          )}
        </div>
      </div>
      <div className="w-full h-[52px] flex">
        <div
          className="h-full border border-solid border-[#B3B3B3] px-2 py-4 bg-[#ebebeb]"
          style={{
            width: "25%",
            textAlign: "start",
          }}
        >
          <p className="text-sm leading-[18px] tracking-[.2em] truncate">
            被験品の返却
          </p>
        </div>

        <div
          className={`h-full ${
            isReadonly
              ? "border border-solid border-[#B3B3B3] px-2 py-4 bg-[#ebebeb]"
              : ""
          }`}
          style={{
            width: "75%",
            textAlign: "start",
          }}
        >
          {isReadonly ? (
            <p className="text-sm leading-[18px] tracking-[.2em] truncate">
              {getSelectedLabel(
                returnTestMaterialOptions,
                requestFormInfo?.returnTestMaterial
              )}
            </p>
          ) : (
            <SelectPopover
              selectedValue={requestFormInfo?.returnTestMaterial}
              setSelectedValue={(newValue) =>
                setRequestFormInfo({
                  ...requestFormInfo,
                  returnTestMaterial: newValue,
                })
              }
              options={returnTestMaterialOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TestContentInfoTable;
