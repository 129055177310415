import React from "react";
import { caseStatusBgColor, caseStatusLabel } from "../../../../constants";

function CaseStatusCell(props) {
  const { caseStatus, width } = props;

  const bgColor = caseStatusBgColor[caseStatus] || caseStatusBgColor.default;

  return (
    <div
      className={`h-full border border-solid border-[#B3B3B3] px-2 py-4`}
      style={{
        width: width,
        textAlign: "center",
        backgroundColor: bgColor,
      }}
    >
      <p className="text-sm leading-[18px] tracking-[.2em] truncate">
        {caseStatusLabel[caseStatus]}
      </p>
    </div>
  );
}

export default CaseStatusCell;
