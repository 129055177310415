import React, { useState } from "react";
import Popup from "./Popup";
import {
  DATE_TIME_FORMAT,
  fileNameType,
  requestFormStatusColor,
  requestFormStatusLabel,
} from "app/constants";
import moment from "moment";
import { commonServices } from "app/services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import messages from "../../../../services/api/messages";
import { toast } from "react-toastify";

function RequestFromCell(props) {
  const { width, requestFormStatus, requestFormInfo, caseInfo } = props;
  const [anchor, setAnchor] = useState(null);

  const handleLeftClickRequestFrom = () => {
    if (requestFormInfo?.generatedPdf) {
      const caseId = caseInfo?.caseId || "";
      const fileType = fileNameType["requestForm"];
      const customerName = caseInfo?.customer?.companyNameKanji || "";
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      commonServices
        .downloadPDFWithFileId(
          requestFormInfo.generatedPdf,
          `${fileType}_${caseId}_${customerName}_${timeStamp}`
        )
        .catch(() => {
          toast.error(messages.downloadFail);
        });
    }
  };

  const handleRightClickRequestFrom = (e) => {
    e.preventDefault();
    setAnchor(e.target);
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: width,
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
          <button
            style={{
              color: requestFormStatusColor[requestFormStatus],
              cursor: "pointer",
            }}
            onClick={handleLeftClickRequestFrom}
            onContextMenu={handleRightClickRequestFrom}
          >
            {requestFormStatusLabel[requestFormStatus]}
          </button>
        </p>
      </div>

      {/* <Popup
        id={`request-form-${requestFormInfo?._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        <div className="flex flex-col">
          <button
            className="bg-[#c7c2c2] w-7 h-7 rounded-full ml-auto flex items-center justify-center"
            onClick={() => setAnchor(null)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <p className="flex gap-2">
            最終更新日:{" "}
            {moment(requestFormInfo?.fileInfo?.updatedAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>
      </Popup> */}
    </>
  );
}

export default RequestFromCell;
