// FE-1
import React, { useContext } from "react";
import { NotificationContext } from "app/layouts/MainLayout";
import BlankAvatar from "app/assets/images/blank_avatar.png";

function ContentLayout(props) {
  const { children, listContact, onSelect, selectingId } = props;
  const { msgPayload } = useContext(NotificationContext);

  return (
    <div className="absolute top-0 mt-[136px] pb-8 w-full h-[calc(100vh-136px)]">
      <div className="custom-container h-full">
        <div className="w-full h-full relative">
          {/* Side Menu Content */}
          {/* <div className="hidden md:block absolute inset-y-0 left-0 w-[259px]  mt-10 pb-14 border border-[#4F1C00]">
            <div className="w-full text-center py-2 bg-[#4F1C00] text-white">チャットリスト</div>
            <div className="w-full h-full py-5 overflow-y-auto">
              {listContact?.map(({ info, _id }) => {
                return (
                  <div className={`px-[16px] cursor-pointer ${selectingId === _id && "bg-[#EBEBEB]"}`} key={_id}>
                    <div
                      onClick={() => {
                        onSelect(_id);
                      }}
                      className={`border-b border-[#4F1C00]   flex gap-4 py-4`}>
                      <img className="h-[40px] w-[40px] object-cover rounded-full" src={BlankAvatar} alt="" />
                      <div className="line-clamp-2">{info?.firstNameKanji + info?.lastNameKanji}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
          {/* Main Content */}
          <div className="w-full h-full pt-10 flex flex-col">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default ContentLayout;
