import React, { useMemo, useState } from "react";
import SelectOption from "./SelectOption";
import SelectOptionInput from "./SelectOptionInput";

function SelectOptionOther(props) {
  const {
    selectedValue,
    onSelect,
    isReadonly,
    otherPrefix = "その他",
    inputWidth = 1,
  } = props;

  const [otherValue, setOtherValue] = useState(() => {
    if (
      !selectedValue ||
      typeof selectedValue !== "string" ||
      !selectedValue.startsWith(otherPrefix)
    )
      return "";
    const indexOfSeparator = selectedValue.indexOf("|");
    if (indexOfSeparator < 0) return;

    return selectedValue.slice(indexOfSeparator + 1);
  });

  const isSelected = useMemo(() => {
    if (!selectedValue || typeof selectedValue !== "string")
      return false;
    return selectedValue.startsWith(otherPrefix);
  }, [selectedValue, otherPrefix]);

  const handleOtherValueChange = (newValue) => {
    if (isSelected) onSelect(`${otherPrefix}|${newValue}`);
    setOtherValue(newValue);
  };

  const handleClickOptionOther = () => {
    if (isSelected) return;
    else onSelect(`${otherPrefix}|${otherValue}`);
  };

  return (
    <>
      <SelectOption
        label="その他"
        value="その他"
        onSelect={handleClickOptionOther}
        isSelected={isSelected}
        isReadonly={isReadonly}
      />
      <div className={`col-span-${inputWidth}`}>
        <SelectOptionInput
          value={otherValue}
          onChange={handleOtherValueChange}
          isSelected={isSelected}
          isReadonly={isReadonly}
        />
      </div>
    </>
  );
}

export default SelectOptionOther;
