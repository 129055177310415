import React from "react";
import SelectOption from "./components/SelectOption";
import SelectOptionOther from "./components/SelectOptionOther";

const storageClassificationOptions = [
  {
    value: "通常保管",
    label: "通常保管",
  },
  {
    value: "冷蔵",
    label: "冷蔵",
  },
  {
    value: "冷凍",
    label: "冷凍",
  },
  {
    value: "完全に遮光",
    label: "完全に遮光",
  },
];

function SelectStorageClassification({
  selectedValue,
  onSelect,
  isReadonly,
  screenWidth,
}) {
  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-center justify-center gap-2.5 py-2 px-2 lg:px-[30px]">
        <SelectOption
          label={storageClassificationOptions[0].label}
          value={storageClassificationOptions[0].value}
          onSelect={onSelect}
          isSelected={
            selectedValue === storageClassificationOptions[0].value
          }
          isReadonly={isReadonly}
        />
        <SelectOption
          label={storageClassificationOptions[1].label}
          value={storageClassificationOptions[1].value}
          onSelect={onSelect}
          isSelected={
            selectedValue === storageClassificationOptions[1].value
          }
          isReadonly={isReadonly}
        />
        <SelectOption
          label={storageClassificationOptions[2].label}
          value={storageClassificationOptions[2].value}
          onSelect={onSelect}
          isSelected={
            selectedValue === storageClassificationOptions[2].value
          }
          isReadonly={isReadonly}
        />
        {screenWidth >= 1024 && <div className=" h-0"></div>}
        <SelectOption
          label={storageClassificationOptions[3].label}
          value={storageClassificationOptions[3].value}
          onSelect={onSelect}
          isSelected={
            selectedValue === storageClassificationOptions[3].value
          }
          isReadonly={isReadonly}
        />
        <SelectOptionOther
          selectedValue={selectedValue}
          onSelect={onSelect}
          isReadonly={isReadonly}
          otherPrefix="その他"
        />
      </div>
    </div>
  );
}

export default SelectStorageClassification;
