import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";
import routePaths from "./routePaths";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import { selectCurrentToken } from "../redux/authReducer";

// Auth Page
import Login from "../pages/AuthPages/Login";
import Register from "../pages/AuthPages/Register";
import ForgotPassword from "../pages/AuthPages/ForgotPassword";
import ResetPassword from "../pages/AuthPages/ResetPassword";

// Common Page
import NotFound from "../pages/NotFound";

// Private Page
import TopMenu from "../pages/TopMenu";
import Dashboard from "../pages/Dashboard";
import SearchOrder from "../pages/Order/SearchOrder";
import UpdateOrder from "../pages/Order/UpdateOrder";
import CreateOrder from "../pages/Order/CreateOrder";
import DetailSystemSettings from "../pages/SystemSettings/DetailSystemSettings";
import UpdateSystemSettings from "../pages/SystemSettings/UpdateSystemSettings";

import ChatPage from "../pages/Chat";

function Router() {
  const element = useRoutes([
    {
      path: "/",
      element: <AuthRoute />,
      children: [
        { path: "/", element: <Navigate to={routePaths.login} replace /> },
        { path: routePaths.login, element: <Login /> },
        { path: routePaths.register, element: <Register /> },
        { path: routePaths.forgotPassword, element: <ForgotPassword /> },
        { path: routePaths.resetPassword, element: <ResetPassword /> },
      ],
    },

    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        { path: "/", element: <Navigate to={routePaths.topMenu} replace /> },
        { path: routePaths.topMenu, element: <TopMenu /> },
        // 1. Dashboard
        { path: routePaths.dashboard, element: <Dashboard /> },

        // 2. Order
        { path: routePaths.order, element: <SearchOrder /> },
        { path: routePaths.orderUpdate(), element: <UpdateOrder /> },
        { path: routePaths.orderCreate, element: <CreateOrder /> },

        {
          path: routePaths.chat,
          element: <ChatPage />,
        },

        // 5. System settings
        { path: routePaths.systemSettings, element: <DetailSystemSettings /> },
        {
          path: routePaths.updateSystemSettings,
          element: <UpdateSystemSettings />,
        },
      ],
    },

    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return element;
}

export default function AppRoute() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}
