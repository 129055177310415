import { getCurrentCustomerInfoId } from "../socket";
import client from "./client";

const saveSubscription = async (subscription) => {
  return client
    .post(`${process.env.REACT_APP_API_URL}/notification/subscribe`, {
      subscription,
      accountId: getCurrentCustomerInfoId(),
    })
    .then((res) => {
      console.log("Save subscription response", res);
    });
};

const deleteSubscription = async (id) => {
  return client
    .get(`${process.env.REACT_APP_API_URL}/notification/unsubscribe?id=${id}`)
    .then((res) => {
      console.log("Delete subscription response", res);
    });
};

const getALlNotification = (accountId) => {
  return client.get(`/notification/all?accountId=${accountId}`);
};

const markReadNotification = (id) => {
  return client.put(`/notification/mark-read?id=${id}`);
};

const notificationServices = {
  deleteSubscription,
  saveSubscription,
  getALlNotification,
  markReadNotification,
};

export default notificationServices;
