import React from "react";
import SelectOption from "./components/SelectOption";
import SelectOptionOther from "./components/SelectOptionOther";

const cosmeticClassificationOptions = [
  {
    value: "洗浄料",
    label: "洗浄料",
  },
  {
    value: "メイク落とし",
    label: "メイク落とし",
  },
  {
    value: "パック",
    label: "パック",
  },

  {
    value: "ベースメイク",
    label: "ベースメイク",
  },
  {
    value: "ポイントメイク",
    label: "ポイントメイク",
  },
  {
    value: "スタイリング",
    label: "スタイリング",
  },

  {
    value: "他 リーブオン化粧品",
    label: "他 リーブオン化粧品",
  },
  {
    value: "原料",
    label: "原料",
  },
];

function SelectCosmeticClassification(props) {
  const { selectedValue, onSelect, isReadonly, screenWidth } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div>
        <div className="w-full h-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 items-center justify-center gap-2.5 py-2 px-2 lg:px-[30px]">
          <SelectOption
            label={cosmeticClassificationOptions[0].label}
            value={cosmeticClassificationOptions[0].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[0].value
            }
            isReadonly={isReadonly}
          />
          <SelectOption
            label={cosmeticClassificationOptions[1].label}
            value={cosmeticClassificationOptions[1].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[1].value
            }
            isReadonly={isReadonly}
          />
          <SelectOption
            label={cosmeticClassificationOptions[2].label}
            value={cosmeticClassificationOptions[2].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[2].value
            }
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label={cosmeticClassificationOptions[3].label}
            value={cosmeticClassificationOptions[3].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[3].value
            }
            isReadonly={isReadonly}
          />
          <SelectOption
            label={cosmeticClassificationOptions[4].label}
            value={cosmeticClassificationOptions[4].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[4].value
            }
            isReadonly={isReadonly}
          />
          <SelectOption
            label={cosmeticClassificationOptions[5].label}
            value={cosmeticClassificationOptions[5].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[5].value
            }
            isReadonly={isReadonly}
          />
          {screenWidth >= 1024 && <div className=" h-0"></div>}
          <SelectOption
            label={cosmeticClassificationOptions[6].label}
            value={cosmeticClassificationOptions[6].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[6].value
            }
            isReadonly={isReadonly}
          />
          <SelectOption
            label={cosmeticClassificationOptions[7].label}
            value={cosmeticClassificationOptions[7].value}
            onSelect={onSelect}
            isSelected={
              selectedValue === cosmeticClassificationOptions[7].value
            }
            isReadonly={isReadonly}
          />
          {screenWidth < 1024 && screenWidth > 640 && <div className=" h-0"></div>}
          <SelectOptionOther
            selectedValue={selectedValue}
            onSelect={onSelect}
            isReadonly={isReadonly}
            otherPrefix="その他"
          />
        </div>
        <div className="py-2 px-2 text-sm text-[#707070]">
          その他の項目については詳細のご記載をお願いします
        </div>
      </div>
    </div>
  );
}

export default SelectCosmeticClassification;
