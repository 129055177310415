import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Divider } from "@mui/material";

export default function NotiDropdown({ icon, list, onClickRead }) {
  const [show, setShow] = useState(false);
  const hasUnread = list?.some(({ isRead }) => !isRead);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShow(open);
  };

  return (
    <div className="relative">
      {list?.length > 0 && hasUnread && (
        <div className="absolute bg-[red] rounded-full w-2 h-2 right-[-0.1em]" />
      )}
      <button
        onClick={toggleDrawer(true)}
        type="button"
        className="w-[30px] h-[30px]"
      >
        <img alt="bell" className="w-full h-full" src={icon} />
      </button>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => {
          setShow(() => false);
        }}
      >
        <div className="min-w-[300px]" />
        {list?.map(({ content, isRead, _id }) => {
          return (
            <React.Fragment key={_id}>
              <div
                onClick={() => onClickRead(_id)}
                className="min-w-[300px] px-4 py-4 flex gap-4 cursor-pointer"
              >
                {content}
                <div className="text-[red] w-[24px]">
                  {!isRead && <>&#11044;</>}
                </div>
              </div>
              <Divider />
            </React.Fragment>
          );
        })}
      </Drawer>
    </div>
  );
}
