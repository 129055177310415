import client from "./client";

const getByScheduleId = (scheduleId) => {
  return client
    .get("/case-info/get-by-test-schedule", {
      params: {
        "test-schedule-id": scheduleId,
      },
    })
    .then((res) => {
      if (res.data?.success) {
        return res.data.result || null;
      }
      throw new Error("Malformed data!");
    });
};
const caseServices = {
  getByScheduleId,
};

export default caseServices;
