import React, { useState } from "react";
import Popup from "./Popup";
import {
  sampleStatus as sampleStatusList,
  sampleStatusColor,
  sampleStatusLabel,
} from "app/constants";
import PrimaryBtn from "../../../UI/Button/PrimaryBtn";
import { projectServices } from "../../../../services/api";
import { toast } from "react-toastify";
import messages from "../../../../services/api/messages";
import PageLoadingBackdrop from "../../../CommonPage/PageLoadingBackdrop";

function SampleCell(props) {
  const { width, sampleStatus, caseInfo, setCaseInfo } = props;
  const [anchor, setAnchor] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);

  const handleRightClickSample = (e) => {
    e.preventDefault();
    if (sampleStatus === sampleStatusList.notStarted) setAnchor(e.target);
  };

  const handleUpdateSampleStatusToConfirmed = () => {
    if (isUpdating || sampleStatus !== sampleStatusList.notStarted) return;

    setIsUpdating(true);
    projectServices
      .updateStatus({
        id: caseInfo._id,
        statusType: "sampleStatus",
        value: sampleStatusList.confirmed,
      })
      .then((res) => {
        const newCaseInfo = res?.data?.result;
        if (!newCaseInfo._id) throw Error("Update error!");

        setIsUpdating(false);
        setAnchor(null);
        setCaseInfo({
          ...caseInfo,
          sampleStatus: newCaseInfo?.sampleStatus,
          caseStatus: newCaseInfo?.caseStatus,
        });
      })
      .catch(() => {
        toast.error(messages.callApiFail);
        setIsUpdating(false);
      });
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: width,
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
          <button
            style={{
              color: sampleStatusColor[sampleStatus],
              cursor: "pointer",
            }}
            onContextMenu={handleRightClickSample}
          >
            {sampleStatusLabel[sampleStatus]}
          </button>
        </p>
      </div>

      <Popup
        id={`sample-${caseInfo._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        {sampleStatus === sampleStatusList.notStarted ? (
          <div className="flex gap-2">
            <PrimaryBtn
              text="配送手配済み"
              onClick={handleUpdateSampleStatusToConfirmed}
            />
            <PrimaryBtn text="戻る" onClick={() => setAnchor(null)} />
          </div>
        ) : null}
      </Popup>
      <PageLoadingBackdrop open={isUpdating} />
    </>
  );
}

export default SampleCell;
