import React from "react";
import InputText from "../Input/InputText";
import { searchAddressComponentByPostalCode } from "../../../../utils/yubinbango";

function AddressFieldGroup(props) {
  const {
    errors,
    values,
    setFieldValue,
    touched,
    handleBlur,
    fieldSuffix = "",
    labelWidth = 136,
    displaysReplacementValueSuffix,
  } = props;

  const handleChangePostalCode = (newValue) => {
    setFieldValue(`postalCode${fieldSuffix}`, newValue);

    if (typeof newValue !== "string") return;
    const postalCode = newValue.replaceAll(/[^0-9]/g, "");
    if (!newValue) return;

    searchAddressComponentByPostalCode(postalCode).then((res) => {
      if (res.region) {
        setFieldValue(`prefecture${fieldSuffix}`, res.region || "");
        setFieldValue(`address1${fieldSuffix}`, res.locality || "");
        setFieldValue(`address2${fieldSuffix}`, res.street || "");
        if (res.extended)
          setFieldValue(`address3${fieldSuffix}`, res.extended || "");
      }
    });
  };

  return (
    <div className="w-full h-auto">
      <InputText
        id={`postalCode${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`postalCode${displaysReplacementValueSuffix}`]
            : values[`postalCode${fieldSuffix}`]
        }
        onChange={handleChangePostalCode}
        label="郵便番号"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="550-0013"
        errors={errors[`postalCode${fieldSuffix}`]}
        touched={touched[`postalCode${fieldSuffix}`]}
      />

      <InputText
        id={`prefecture${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`prefecture${displaysReplacementValueSuffix}`]
            : values[`prefecture${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`prefecture${fieldSuffix}`, newValue)
        }
        label="都道府県"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="大阪府"
        errors={errors[`prefecture${fieldSuffix}`]}
        touched={touched[`prefecture${fieldSuffix}`]}
      />

      <InputText
        id={`address1${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`address1${displaysReplacementValueSuffix}`]
            : values[`address1${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`address1${fieldSuffix}`, newValue)
        }
        label="市区町村"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="大阪市西区新町"
        errors={errors[`address1${fieldSuffix}`]}
        touched={touched[`address1${fieldSuffix}`]}
      />

      <InputText
        id={`address2${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`address2${displaysReplacementValueSuffix}`]
            : values[`address2${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`address2${fieldSuffix}`, newValue)
        }
        label="番地"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="1-28-3"
        errors={errors[`address2${fieldSuffix}`]}
        touched={touched[`address2${fieldSuffix}`]}
      />

      <InputText
        id={`address3${fieldSuffix}`}
        value={
          displaysReplacementValueSuffix
            ? values[`address3${displaysReplacementValueSuffix}`]
            : values[`address3${fieldSuffix}`]
        }
        onChange={(newValue) =>
          setFieldValue(`address3${fieldSuffix}`, newValue)
        }
        label="ビル・部屋番号など"
        labelWidth={labelWidth}
        handleBlur={handleBlur}
        disabled={displaysReplacementValueSuffix}
        placeholder="四ツ橋グランスクエア4F"
        errors={errors[`address3${fieldSuffix}`]}
        touched={touched[`address3${fieldSuffix}`]}
      />
    </div>
  );
}

export default AddressFieldGroup;
