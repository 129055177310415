import React from "react";

function InputName(props) {
  const { value, onChange, isReadonly } = props;

  return (
    <div className="w-full">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        readOnly={isReadonly}
        className="w-full h-full !outline-none rounded-[10px] px-3 py-3 text-start text-custom-size"
      />
    </div>
  );
}

export default InputName;
