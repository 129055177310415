import React, { useState } from "react";
import Popup from "./Popup";
import {
  DATE_TIME_FORMAT,
  reportStatus as reportStatusList,
  reportStatusColor,
  reportStatusLabel,
  fileNameType,
} from "app/constants";
import moment from "moment";
import { commonServices, projectServices } from "app/services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import PrimaryBtn from "../../../UI/Button/PrimaryBtn";
import { toast } from "react-toastify";
import messages from "../../../../services/api/messages";

function ReportCell(props) {
  const { width, reportStatus, reportInfo, caseInfo, setCaseInfo } = props;
  const [anchor, setAnchor] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleLeftClickReport = () => {
    if (reportInfo?.generatedPdf) {
      const caseId = caseInfo?.caseId || "";
      const fileType = fileNameType["report"];
      const customerName = caseInfo?.customer?.companyNameKanji || "";
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      commonServices
        .downloadPDFWithFileId(
          reportInfo.generatedPdf,
          `${fileType}_${caseId}_${customerName}_${timeStamp}`
        )
        .catch(() => {
          toast.error(messages.downloadFail);
        });
    }
  };

  const handleRightClickReport = (e) => {
    e.preventDefault();

    if (
      reportStatus === reportStatusList.confirmed ||
      reportStatus === reportStatusList.submitted
    )
      setAnchor(e.target);
  };

  const handleUpdateReportStatus = (newStatus) => {
    if (isUpdating || reportStatus !== reportStatusList.confirmed) return;

    setIsUpdating(true);
    projectServices
      .updateStatus({
        id: caseInfo._id,
        statusType: "reportStatus",
        value: newStatus,
      })
      .then((res) => {
        const newCaseInfo = res?.data?.result;
        if (!newCaseInfo._id) throw Error("Update error!");

        setIsUpdating(false);
        setAnchor(null);
        setCaseInfo({
          ...caseInfo,
          reportStatus: newCaseInfo?.reportStatus,
          caseStatus: newCaseInfo?.caseStatus,
        });
      })
      .catch(() => {
        toast.error(messages.callApiFail);
        setIsUpdating(false);
      });
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: width,
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
          <button
            style={{
              color: reportStatusColor[reportStatus],
              cursor: "pointer",
            }}
            onClick={handleLeftClickReport}
            onContextMenu={handleRightClickReport}
          >
            {reportStatusLabel[reportStatus]}
          </button>
        </p>
      </div>

      <Popup
        id={`request-form-${reportInfo?._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        {reportStatus === reportStatusList.confirmed ? (
          <div className="flex flex-col gap-2">
            <p className="flex gap-2">
              最終更新日:{" "}
              {moment(
                reportInfo?.fileInfo?.updatedAt || reportInfo?.updatedAt
              ).format(DATE_TIME_FORMAT)}
            </p>
            <div className="flex gap-2">
              <PrimaryBtn
                text="承認"
                onClick={() =>
                  handleUpdateReportStatus(reportStatusList.submitted)
                }
              />
              <PrimaryBtn
                text="差し戻し"
                onClick={() =>
                  handleUpdateReportStatus(reportStatusList.rejected)
                }
              />
              <PrimaryBtn text="戻る" onClick={() => setAnchor(null)} />
            </div>
          </div>
        ) : null}

        {reportStatus === reportStatusList.submitted ? (
          <div className="flex flex-col">
            <button
              className="bg-[#c7c2c2] w-7 h-7 rounded-full ml-auto flex items-center justify-center"
              onClick={() => setAnchor(null)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <p className="flex gap-2">
              最終更新日:{" "}
              {moment(
                reportInfo?.fileInfo?.updatedAt || reportInfo?.updatedAt
              ).format(DATE_TIME_FORMAT)}
            </p>
          </div>
        ) : null}
      </Popup>
    </>
  );
}

export default ReportCell;
