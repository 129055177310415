import client from "./client";
import { v4 as uuidv4 } from "uuid";
import {
  planPresentationStatus,
  testReturnStatus,
} from "../../constants";

const initTestMaterialInfo = {
  name: "",
  preprocess: "",
  materialNumber: "",
  capacity: 15,
  classification: "",
  concentraction: 0,
  dilutionMedia: "",
  cosmeticType: "",
  cosmeticClassification: "",
  cosmeticUsage: "",
  skinDying: undefined,
  volatileComponents: "",
  ingrediants: "",
  volatileComponentsHandling: "",
  originalMaterialExperience: "",
  foodAllergy: "",
  storageClassification: "",
  memo: "",
};

const genPreprocess = (testMaterialItem) => {
  if (
    testMaterialItem.concentraction &&
    testMaterialItem.dilutionMedia === "注射用蒸留水"
  ) {
    return `注射用蒸留水で${
      Number(testMaterialItem.concentraction) || ""
    }%(w/w)に調製し、試験に供した。`;
  }
  if (
    testMaterialItem.volatileComponents === "含む" &&
    testMaterialItem.volatileComponentsHandling === "required"
  ) {
    return "揮発成分を含むため事前に揮発させ、試験に供した。";
  }
  return "原体のまま試験に供した。";
};

const getTestMaterialInfos = (rawData) => {
  if (!Array.isArray(rawData)) return [];

  return rawData.map((item) => ({
    name: item.name,
    preprocess: item.preprocess,
    materialNumber: item.materialNumber,
    capacity: item.capacity,
    classification: item.classification,
    concentraction: item.concentraction,
    dilutionMedia: item.dilutionMedia,
    cosmeticType: item.cosmeticType,
    cosmeticClassification: item.cosmeticClassification,
    cosmeticUsage: item.cosmeticUsage,
    skinDying: item.skinDying,
    volatileComponents: item.volatileComponents,
    ingrediants: item.ingrediants,
    volatileComponentsHandling: item.volatileComponentsHandling,
    originalMaterialExperience: item.originalMaterialExperience,
    foodAllergy: item.foodAllergy,
    storageClassification: item.storageClassification,
    memo: item.memo,
  }));
};

const getTestContentInfos = (rawData) => {
  if (!Array.isArray(rawData)) return [];

  return rawData.map((contentItem) => {
    return {
      testItemName: contentItem.testItemName,
      testPeopleNumber: contentItem.testPeopleNumber,
      testProductNumber: contentItem.testProductNumber,
      testMaterialInfos: getTestMaterialInfos(
        contentItem.testMaterialData
      ),
    };
  });
};

const searchByTestScheduleId = (idSchedule) => {
  return client
    .get("/request-form-info/get-by-test-schedule", {
      params: {
        "test-schedule-id": idSchedule,
      },
    })
    .then((resRequestForm) => {
      if (resRequestForm.data?.success) {
        const requestForm = resRequestForm.data.result;
        if (requestForm)
          return {
            data: {
              id: requestForm._id,
              planPresentation: requestForm.planPresentation,
              returnTestMaterial: requestForm.returnTestMaterial,
              testContentInfos: getTestContentInfos(
                requestForm.content
              ),
            },
          };

        return {
          data: {},
        };
      }
      throw new Error("Malformed data!");
    });
};

const getByCaseId = (caseId) => {
  return client
    .get("/request-form-info/get-by-case-id", {
      params: {
        "case-id": caseId,
      },
    })
    .then((res) => {
      if (res.data?.success) {
        if (res.data.result) {
          const requestForm = res.data.result;
          return {
            data: {
              id: requestForm._id,
              planPresentation: requestForm.planPresentation,
              returnTestMaterial: requestForm.returnTestMaterial,
              testSchedule: requestForm.testSchedule,
              generatedPdf: requestForm.generatedPdf,
              testContentInfos: getTestContentInfos(
                requestForm.content
              ),
            },
          };
        } else {
          return { data: null };
        }
      }
      throw new Error("Malformed data!");
    });
};

const searchById = (id) => {
  return client
    .get("/request-form-info/get-request-form-id", {
      params: {
        id: id,
      },
    })
    .then((resRequestForm) => {
      if (
        resRequestForm.data?.success &&
        resRequestForm.data.result
      ) {
        const requestForm = resRequestForm.data.result;
        return {
          data: {
            id: requestForm._id,
            planPresentation: requestForm.planPresentation,
            returnTestMaterial: requestForm.returnTestMaterial,
            testSchedule: requestForm.testSchedule,
            generatedPdf: requestForm.generatedPdf,
            testContentInfos: getTestContentInfos(
              requestForm.content
            ),
          },
        };
      }
      throw new Error("Malformed data!");
    });
};

const getInitRequestFormByQuotationInfoId = (id) => {
  return client
    .get("/quotation-info/get-by-id", {
      params: { id },
    })
    .then((resQuotation) => {
      if (resQuotation.data?.success) {
        const result = resQuotation.data?.result;

        if (result) {
          const quotationTasks = Array.isArray(
            result?.quotation_tasks
          )
            ? result?.quotation_tasks
            : [];

          return {
            data: {
              planPresentation: planPresentationStatus.required,
              returnTestMaterial: testReturnStatus.required,
              testContentInfos: quotationTasks.map((item) => {
                const testContentInfoItem = {
                  testItemName: item.name || "",
                  testProductNumber: item.testSampleNumber || 0,
                  testPeopleNumber: item.inspectionPeopleNumber || 0,
                  testMaterialInfos: new Array(
                    item.testSampleNumber || 0
                  )
                    .fill(1)
                    .map(() => ({
                      ...initTestMaterialInfo,
                      tempId: uuidv4(),
                    })),
                };

                return testContentInfoItem;
              }),
            },
          };
        }
        return { data: null };
      }
      throw new Error("Malformed data!");
    });
};

const createRequestForm = (formData) => {
  const data = {
    ...formData,
    testContentInfos: Array.isArray(formData?.testContentInfos)
      ? formData?.testContentInfos.map((testContentItem) => ({
          ...testContentItem,
          testMaterialInfos: Array.isArray(
            testContentItem?.testMaterialInfos
          )
            ? testContentItem?.testMaterialInfos.map(
                (testMaterialItem) => ({
                  ...testMaterialItem,
                  preprocess: genPreprocess(testMaterialItem),
                })
              )
            : [],
        }))
      : [],
  };

  return client
    .post("/request-form-info/create", data)
    .then((resQuotation) => {
      if (resQuotation.data?.success && resQuotation.data?.result) {
        return {
          data: {
            id: resQuotation.data?.result?.id,
          },
        };
      }
      throw new Error("Malformed data!");
    });
};

const updateRequestForm = (formData) => {
  const id = formData.id;

  const data = {
    ...formData,
    id: undefined,
    testContentInfos: Array.isArray(formData?.testContentInfos)
      ? formData?.testContentInfos.map((testContentItem) => ({
          ...testContentItem,
          testMaterialInfos: Array.isArray(
            testContentItem?.testMaterialInfos
          )
            ? testContentItem?.testMaterialInfos.map(
                (testMaterialItem) => ({
                  ...testMaterialItem,
                  preprocess: genPreprocess(testMaterialItem),
                })
              )
            : [],
        }))
      : [],
  };

  return client
    .put(`/request-form-info/update?id=${id}`, data)
    .then((resQuotation) => {
      if (resQuotation.data?.success && resQuotation.data?.result) {
        return {
          data: {
            id: resQuotation.data?.result?.id,
          },
        };
      }
      throw new Error("Malformed data!");
    });
};

const genFileRequestForm = (formData) => {
  return client
    .post("/request-form-info/create-file-download", formData)
    .then((resQuotation) => {
      if (resQuotation.data?.success && resQuotation.data?.result) {
        return {
          data: resQuotation.data?.result,
        };
      }
      throw new Error("Malformed data!");
    });
};

const orderServices = {
  searchByTestScheduleId,
  getInitRequestFormByQuotationInfoId,
  createRequestForm,
  getByCaseId,
  updateRequestForm,
  genFileRequestForm,
  searchById,
};
export default orderServices;
