import React, { useState } from "react";

import TableInfoHeader from "../../CommonComponents/TableInfo/TableInfoHeader";
import PageNoData from "../../../CommonPage/PageNoData";
import QuotationCell from "./QuotationCell";
import RequestFromCell from "./RequestFromCell";
import TestPlanCell from "./TestPlanCell";
import SampleCell from "./SampleCell";
import ReportCell from "./ReportCell";
import InvoiceCell from "./InvoiceCell";
import CaseStatusCell from "./CaseStatusCell";
import SamplePeopleNumberCell from "./SamplePeopleNumberCell";
import SampleNumberCell from "./SampleNumberCell";
import QuotationPriceCell from "./QuotationPriceCell";

const tableConfig = [
  {
    title: "案件ID",
    width: "11.13%",
    dataKey: "caseId",
    textAlign: "center",
  },
  {
    title: "見積り",
    width: "8.69%",
    dataKey: "quotation",
    textAlign: "center",
  },
  {
    title: "依頼書",
    width: "8.69%",
    dataKey: "requestForm",
    textAlign: "center",
  },
  {
    title: "計画書",
    width: "8.69%",
    dataKey: "testPlan",
    textAlign: "center",
  },
  {
    title: "サンプル",
    width: "8.69%",
    dataKey: "sampleStatus",
    textAlign: "center",
  },
  {
    title: "報告書",
    width: "8.69%",
    dataKey: "report",
    textAlign: "center",
  },
  {
    title: "請求書",
    width: "8.69%",
    dataKey: "invoice",
    textAlign: "center",
  },
  {
    title: "検査人数",
    width: "8.69%",
    dataKey: "samplePeopleNumber",
    textAlign: "center",
  },
  {
    title: "検体数",
    width: "8.69%",
    dataKey: "sampleNumber",
    textAlign: "center",
  },
  {
    title: "見積金額(税抜)",
    width: "10.43%",
    dataKey: "",
    textAlign: "center",
  },
  {
    title: "状況",
    width: "8.69%",
    dataKey: "caseStatus",
    textAlign: "center",
    CustomTableCell: CaseStatusCell,
  },
];

function UpdateCaseRow({ caseInfo, setCaseInfo }) {
  return (
    <div className="w-full h-[52px] flex">
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: tableConfig[0].width,
        }}
      >
        <p className="text-custom-size-xs leading-[18px] tracking-[.2em] truncate text-center">
          {caseInfo?.quotationInfo?.quotationId || ""}
        </p>
      </div>
      <QuotationCell
        width={tableConfig[1].width}
        quotationStatus={caseInfo.quotationStatus}
        quotationInfo={caseInfo.quotationInfo}
        caseInfo={caseInfo}
      />
      <RequestFromCell
        width={tableConfig[2].width}
        requestFormStatus={caseInfo.requestFormStatus}
        requestFormInfo={caseInfo.requestFormInfo}
        caseInfo={caseInfo}
      />
      <TestPlanCell
        width={tableConfig[3].width}
        testPlanStatus={caseInfo.testPlanStatus}
        testPlanInfo={caseInfo.testPlanInfo}
        caseInfo={caseInfo}
        setCaseInfo={setCaseInfo}
      />
      <SampleCell
        width={tableConfig[4].width}
        sampleStatus={caseInfo.sampleStatus}
        caseInfo={caseInfo}
        setCaseInfo={setCaseInfo}
      />
      <ReportCell
        width={tableConfig[5].width}
        reportStatus={caseInfo.reportStatus}
        reportInfo={caseInfo.reportInfo}
        caseInfo={caseInfo}
        setCaseInfo={setCaseInfo}
      />
      <InvoiceCell
        width={tableConfig[6].width}
        invoiceStatus={caseInfo.invoiceStatus}
        invoiceInfo={caseInfo.invoiceInfo}
        caseInfo={caseInfo}
        setCaseInfo={setCaseInfo}
      />
      <SamplePeopleNumberCell
        width={tableConfig[7].width}
        caseInfo={caseInfo}
      />
      <SampleNumberCell
        width={tableConfig[8].width}
        caseInfo={caseInfo}
      />
      <QuotationPriceCell
        width={tableConfig[9].width}
        caseInfo={caseInfo}
      />
      <CaseStatusCell
        width={tableConfig[10].width}
        caseStatus={caseInfo.caseStatus}
      />
    </div>
  );
}

function TransactionHistoryTable(props) {
  const { initCaseList } = props;

  const [caseList, setCaseList] = useState(initCaseList);

  const handleUpdateCaseInfo = (index, newValue) => {
    if (!Array.isArray(caseList)) return;
    const newCaseList = [...caseList];

    newCaseList[index] = newValue;
    setCaseList(newCaseList);
  };

  return (
    <div className="w-full h-full overflow-auto relative min-w-[900px] custom-scrollbar pr-1.5">
      <TableInfoHeader
        tableConfig={tableConfig}
        headerFontSize="13px"
      />

      {Array.isArray(caseList) && caseList.length > 0 ? (
        caseList.map((caseInfo, index) => (
          <UpdateCaseRow
            key={index}
            caseInfo={caseInfo}
            setCaseInfo={(newValue) =>
              handleUpdateCaseInfo(index, newValue)
            }
          />
        ))
      ) : (
        <PageNoData />
      )}
    </div>
  );
}

export default TransactionHistoryTable;
