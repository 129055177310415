import React, { useState } from "react";
import Popup from "./Popup";
import {
  DATE_TIME_FORMAT,
  fileNameType,
  testPlanStatusColor,
  testPlanStatusLabel,
  testPlanStatus as testPlanStatusValue,
} from "app/constants";
import moment from "moment";
import { commonServices, projectServices } from "app/services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import messages from "../../../../services/api/messages";
import PrimaryBtn from "../../../UI/Button/PrimaryBtn";
function TestPlanCell({
  width,
  testPlanStatus,
  testPlanInfo,
  caseInfo,
  setCaseInfo,
}) {
  const [anchor, setAnchor] = useState(null);

  const isSubmitted =
    testPlanStatus === testPlanStatusValue.submitted;
  const isNotStarted =
    testPlanStatus === testPlanStatusValue.notStarted;
  const isNone = testPlanStatus === testPlanStatusValue.none;

  const handleLeftClickTestPlan = () => {
    if (testPlanInfo?.generatedPdf) {
      const caseId = caseInfo?.caseId || "";
      const fileType = fileNameType["testPlan"];
      const customerName = caseInfo?.customer?.companyNameKanji || "";
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      commonServices
        .downloadPDFWithFileId(
          testPlanInfo.generatedPdf,
          `${fileType}_${caseId}_${customerName}_${timeStamp}`
        )
        .catch(() => {
          toast.error(messages.downloadFail);
        });
    }
  };

  const handleRightClickTestPlan = (e) => {
    e.preventDefault();
    if (!isNone) {
      setAnchor(e.target);
    }
  };

  const handleUpdateTestPlanStatus = () => {
    projectServices
      .updateStatus({
        id: caseInfo._id,
        statusType: "testPlanStatus",
        value: testPlanStatusValue.none,
      })
      .then((res) => {
        const newCaseInfo = res?.data?.result;
        if (!newCaseInfo._id) {
          throw Error("Update error!");
        }
        setAnchor(null);
        setCaseInfo({
          ...caseInfo,
          testPlanStatus: newCaseInfo?.testPlanStatus,
        });
        toast.success(messages.update(true));
      })
      .catch(() => {
        toast.error(messages.callApiFail);
      });
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: width,
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
          <button
            style={{
              color: testPlanStatusColor[testPlanStatus],
              cursor: "pointer",
            }}
            onClick={handleLeftClickTestPlan}
            onContextMenu={handleRightClickTestPlan}
          >
            {testPlanStatusLabel[testPlanStatus]}
          </button>
        </p>
      </div>

      <Popup
        id={`test-plan-${testPlanInfo?._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        {isSubmitted && (
          <div className="flex flex-col">
            <button
              className="bg-[#c7c2c2] w-7 h-7 rounded-full ml-auto flex items-center justify-center"
              onClick={() => setAnchor(null)}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
            <p className="flex gap-2">
              最終更新日:{" "}
              {moment(testPlanInfo?.fileInfo?.updatedAt).format(
                DATE_TIME_FORMAT
              )}
            </p>
          </div>
        )}
        {isNotStarted && (
          <div className="flex gap-2">
            <PrimaryBtn
              text="無に変更"
              onClick={handleUpdateTestPlanStatus}
            />
            <PrimaryBtn text="戻る" onClick={() => setAnchor(null)} />
          </div>
        )}
      </Popup>
    </>
  );
}

export default TestPlanCell;
