import React, { useMemo, useState, useEffect } from "react";
import SelectClassification from "./SelectClassification";
import InputConcentration from "./InputConcentration";
import SelectDilutionMedia from "./SelectDilutionMedia";
import SelectCosmeticType from "./SelectCosmeticType";
import SelectCosmeticClassification from "./SelectCosmeticClassification";
import SelectCosmeticUsage from "./SelectCosmeticUsage";
import SelectSkinDying from "./SelectSkinDying";
import InputMemo from "./InputMemo";
import SelectStorageClassification from "./SelectStorageClassification";
import SelectFoodAllergy from "./SelectFoodAllergy";
import SelectOriginalMaterialExperience from "./SelectOriginalMaterialExperience";
import SelectVolatileComponentsHandling from "./SelectVolatileComponentsHandling";
import InputIngrediants from "./InputIngrediants";
import SelectVolatileComponents from "./SelectVolatileComponents";
import InputName from "./InputName";

const blankTestMaterial = {
  name: "",
  preprocess: "",
  materialNumber: "",
  capacity: undefined,
  classification: "",
  concentraction: undefined,
  dilutionMedia: "",
  cosmeticType: "",
  cosmeticClassification: "",
  cosmeticUsage: "",
  skinDying: undefined,
  volatileComponents: "",
  ingrediants: "",
  volatileComponentsHandling: "",
  originalMaterialExperience: "",
  foodAllergy: "",
  storageClassification: "",
  memo: "",
};

function TestMaterialInfoTableForm({
  testMaterialInfo,
  setTestMaterialInfo,
  testMaterialNo,
  isReadonly,
}) {
  const testMaterialDataToShow = useMemo(
    () => testMaterialInfo || blankTestMaterial,
    [testMaterialInfo]
  );

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full h-auto mb-12">
      <div className="text-[#707070] mb-4">
        以下チェック項目以外にご要望がございましたら、備考にご記入ください。
      </div>
      <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
        <p className="text-custom-size px-1 truncate">
          被験品情報　No.{testMaterialNo}
        </p>
      </div>

      <div className="w-full flex">
        <div className="w-[19.4%] h-auto">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">被験品名</p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
            <InputName
              value={testMaterialDataToShow?.name || ""}
              onChange={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  name: newValue,
                })
              }
              isReadonly={isReadonly}
            />
            <div className="text-sm text-[#707070] pl-2 pb-2">
              <div>※改行せずに入力をお願いいたします。</div>
              <div>
                ※ご指定がなければ、英数字スペースは半角として扱います
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex">
        <div className="w-[19.4%] h-auto shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">区分</p>
          </div>
        </div>

        <div className="w-[17.7%] h-auto">
          <SelectClassification
            selectedValue={testMaterialDataToShow?.classification}
            onSelect={(newValue) =>
              testMaterialDataToShow &&
              setTestMaterialInfo({
                ...testMaterialInfo,
                classification: newValue,
              })
            }
            isReadonly={isReadonly}
          />
        </div>

        <div className="w-[15.9%] h-auto">
          <div className="w-full h-full flex flex-col">
            <div className="w-full h-[40px] border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center text-custom-size-sm">
              試験濃度
            </div>
            <div className="h-[calc(100%-20px)] w-full">
              <InputConcentration
                value={testMaterialDataToShow?.concentraction || ""}
                onChange={(newValue) =>
                  testMaterialDataToShow &&
                  setTestMaterialInfo({
                    ...testMaterialInfo,
                    concentraction: newValue,
                  })
                }
                isReadonly={isReadonly}
              />
            </div>
          </div>
        </div>

        <div className="w-[13.8%] h-auto">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex flex-col gap-2 items-center justify-center">
            <p className="text-custom-size-sm">希釈媒体</p>
            <p className="text-[#707070] text-xs mx-2">
              希釈が必要な場合 ご記入下さい
            </p>
          </div>
        </div>

        <div className="w-[33.2%] h-auto">
          <div className="h-full w-full">
            <SelectDilutionMedia
              selectedValue={testMaterialDataToShow?.dilutionMedia}
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  dilutionMedia: newValue,
                })
              }
              isReadonly={isReadonly}
              screenWidth={screenWidth}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-auto flex justify-stretch">
        <div className="w-[19.4%] shrink-0 flex">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">
              化粧品情報
            </p>
          </div>
        </div>

        <div className="w-[80.6%] flex flex-col">
          {/* 種類 */}
          <div className="w-full flex">
            <div className="w-[130px] h-full shrink-0">
              <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
                <p className="text-custom-size px-1 truncate">種類</p>
              </div>
            </div>
            <div className="w-[calc(100%-130px)] h-full">
              <div className="h-full w-full">
                <SelectCosmeticType
                  selectedValue={testMaterialDataToShow?.cosmeticType}
                  onSelect={(newValue) =>
                    testMaterialDataToShow &&
                    setTestMaterialInfo({
                      ...testMaterialInfo,
                      cosmeticType: newValue,
                    })
                  }
                  isReadonly={isReadonly}
                  screenWidth={screenWidth}
                />
              </div>
            </div>
          </div>

          {/* 分類 */}
          <div className="w-full flex">
            <div className="w-[130px] h-full shrink-0">
              <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
                <p className="text-custom-size px-1 truncate">分類</p>
              </div>
            </div>

            <div className="w-[calc(100%-130px)] h-full">
              <div className="h-full w-full">
                <SelectCosmeticClassification
                  selectedValue={
                    testMaterialDataToShow?.cosmeticClassification
                  }
                  onSelect={(newValue) =>
                    testMaterialDataToShow &&
                    setTestMaterialInfo({
                      ...testMaterialInfo,
                      cosmeticClassification: newValue,
                    })
                  }
                  isReadonly={isReadonly}
                  screenWidth={screenWidth}
                />
              </div>
            </div>
          </div>

          {/* 用途 */}
          <div className="w-full flex">
            <div className="w-[130px] h-full shrink-0">
              <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
                <p className="text-custom-size px-1 truncate">用途</p>
              </div>
            </div>

            <div className="w-[calc(100%-130px)] h-full">
              <div className="h-full w-full">
                <SelectCosmeticUsage
                  selectedValue={
                    testMaterialDataToShow?.cosmeticUsage
                  }
                  onSelect={(newValue) =>
                    testMaterialDataToShow &&
                    setTestMaterialInfo({
                      ...testMaterialInfo,
                      cosmeticUsage: newValue,
                    })
                  }
                  isReadonly={isReadonly}
                  screenWidth={screenWidth}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex">
        <div className="w-[19.4%] h-auto shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">
              皮膚への染色
            </p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-full w-full">
            <SelectSkinDying
              selectedValue={testMaterialDataToShow?.skinDying}
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  skinDying: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex">
        <div className="w-[19.4%] h-auto shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">揮発成分</p>
          </div>
        </div>

        <div className="flex-1 h-auto">
          <div className="h-full w-full">
            <SelectVolatileComponents
              selectedValue={
                testMaterialDataToShow?.volatileComponents
              }
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  volatileComponents: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>

        <div className="w-[30%] md:w-[45.2%]h-auto">
          <div className="w-full h-full flex flex-col">
            <div className="w-full h-[30px] border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center text-custom-size-sm">
              成分名・配合量
            </div>
            <div className="h-[calc(100%-20px)] w-full">
              <InputIngrediants
                value={testMaterialDataToShow?.ingrediants || ""}
                onChange={(newValue) =>
                  testMaterialDataToShow &&
                  setTestMaterialInfo({
                    ...testMaterialInfo,
                    ingrediants: newValue,
                  })
                }
                isReadonly={isReadonly}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 h-auto">
          <div className="h-full w-full">
            <SelectVolatileComponentsHandling
              selectedValue={
                testMaterialDataToShow?.volatileComponentsHandling
              }
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  volatileComponentsHandling: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-[292px] flex">
        <div className="w-[19.4%] h-full shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">
              原料の使用経験
            </p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-full w-full">
            <SelectOriginalMaterialExperience
              selectedValue={
                testMaterialDataToShow?.originalMaterialExperience
              }
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  originalMaterialExperience: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-[92px] flex">
        <div className="w-[19.4%] h-full shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">
              食物アレルギー物質
            </p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-full w-full">
            <SelectFoodAllergy
              selectedValue={testMaterialDataToShow?.foodAllergy}
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  foodAllergy: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-[19.4%] h-auto shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">保管方法</p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-w-full">
            <SelectStorageClassification
              selectedValue={
                testMaterialDataToShow?.storageClassification
              }
              onSelect={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  storageClassification: newValue,
                })
              }
              isReadonly={isReadonly}
              screenWidth={screenWidth}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[92px] flex">
        <div className="w-[19.4%] h-full shrink-0">
          <div className="h-full w-full border border-solid border-[#B3B3B3] bg-[#EBEBEB] flex items-center justify-center">
            <p className="text-custom-size px-1 truncate">備考</p>
          </div>
        </div>

        <div className="w-[80.6%] h-full">
          <div className="h-full w-full">
            <InputMemo
              value={testMaterialDataToShow?.memo || ""}
              onChange={(newValue) =>
                testMaterialDataToShow &&
                setTestMaterialInfo({
                  ...testMaterialInfo,
                  memo: newValue,
                })
              }
              isReadonly={isReadonly}
            />
          </div>
        </div>
      </div>

      <div className="text-[#707070] text-sm">
        <div className="mt-6 flex flex-col gap-2">
          <p className="font-bold">
            【パッチテスト全般について、ご注意いただきたいこと】
          </p>
          <p>
            ※1
            製品の実使用時に洗い流しが必要なもの（洗浄料など）は、原則として注射用蒸留水で1.0％(w/w)に希釈して試験を実施いたします。
          </p>
          <p>
            ※2
            皮膚に着色する被験品の場合、判定に影響する可能性がありますのでお知らせください。着色が強い場合には試験を実施できないことがあります。また、試験を実施しても、判定医師が試験品の色が皮膚反応の判定に影響すると判断した場合、報告書に「参考データ」と記載されます。
          </p>
          <p>
            ※3
            新規成分を含む場合や、市場で使用実績のない高い濃度を配合した原料を含む場合は、被験者保護の観念より試験を実施することができません。またヒト由来の原料を含む場合につきましても、安全性が確立されていないことから、試験を実施できません。
          </p>
          <p>
            ※化粧品原料基準外の原料を使用の場合別途安全性データの提出をお願いします。
          </p>
        </div>
        <div className="mt-6 flex flex-col gap-2">
          <p className="font-bold">
            【依頼書の記入時にご注意いただきたいこと】
          </p>
          <p>
            ・フォント:「O」（オー）と「0」（ゼロ）、「l」（エル）と「1」（イチ）、スペースの半角と全角など、識別しにくい文字にはフリガナや補足説明の記入をお願いします。
          </p>
          <p>
            ・試験濃度：重量パーセント表記（w/w）で記入をお願いします。
          </p>
          <p>
            ・化粧品の種類：該当する分類をご記入下さい。選択肢がない場合は、「その他」欄に詳細の記入をお願いします。
          </p>
          <p>
            ・皮膚への着色の目安：オイル等で拭き取っても被験品の色がとれない可能性がある場合に「あり」にチェックを入れてください。
          </p>
          <p>
            ・備考欄：被験品の取り扱いや前処理について注意すべき事項などがありましたら、ご記入をお願いします（保管上の注意やあたためないと溶けにくいなど）
          </p>
          <p>ご不明な点がございましたら、お問い合わせ下さい。</p>
        </div>
      </div>
    </div>
  );
}

export default TestMaterialInfoTableForm;
