import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, matchPath } from "react-router-dom";
import MainLayout from "app/layouts/MainLayout";

import routePaths from "../routePaths";
import { selectCurrentToken } from "../../redux/authReducer";

const routeBreadcrumb = [
  { path: "/", breadcrumb: [] },
  {
    path: routePaths.topMenu,
    breadcrumb: [{ label: "業務管理システム" }, { label: "トップ" }],
  },
  // 1. Dashboard
  {
    path: routePaths.dashboard,
    breadcrumb: [{ label: "業務管理システム" }, { label: "ダッシュボード" }],
  },

  // 2. Order
  {
    path: routePaths.order,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "発注書の管理" },
      { label: "発注書の検索" },
    ],
  },
  {
    path: routePaths.orderUpdate(),
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "発注書の管理" },
      { label: "発注書の修正" },
    ],
  },
  {
    path: routePaths.orderCreate,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "発注書の管理" },
      { label: "発注書の作成" },
    ],
  },

  // 3. System setting
  {
    path: routePaths.systemSettings,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "自社情報" },
    ],
  },
  {
    path: routePaths.updateSystemSettings,
    breadcrumb: [
      { label: "業務管理システム" },
      { label: "システム設定" },
      { label: "自社情報の変更" },
    ],
  },

  // 4. Chat
  {
    path: routePaths.chat,
    breadcrumb: [{ label: "業務管理システム" }, { label: "チャット" }],
  },
];

export default function PrivateRoute(props) {
  const location = useLocation();

  const token = useSelector(selectCurrentToken);

  const breadcrumb = useMemo(() => {
    if (!token) return [];

    const breadcrumbObj = routeBreadcrumb.find((item) =>
      matchPath(item.path, location.pathname)
    );

    if (breadcrumbObj && Array.isArray(breadcrumbObj.breadcrumb))
      return breadcrumbObj.breadcrumb;
    return [];
  }, [location.pathname, token]);

  if (!token) {
    return (
      <Navigate to={routePaths.login} state={{ from: location }} replace />
    );
  }

  return (
    <MainLayout breadcrumb={breadcrumb}>
      <Outlet context={[breadcrumb]} />
    </MainLayout>
  );
}
