import React from "react";

function InputMemo(props) {
  const { value, onChange, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      {isReadonly ? (
        <div className="w-full h-full flex items-center justify-start text-custom-size px-5">
          {value || "-"}
        </div>
      ) : (
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          readOnly={isReadonly}
          className="w-full h-full !outline-none rounded-[10px] px-3 py-3 text-start text-custom-size"
        />
      )}
    </div>
  );
}

export default InputMemo;
