import React from "react";
import SelectOption from "./components/SelectOption";

const foodAllergy = [
  {
    value: "特定原材料由来成分を含む（えび・かに・卵・乳・小麦・そば・落花生）",
    label: "特定原材料由来成分を含む（えび・かに・卵・乳・小麦・そば・落花生）",
  },
  {
    value: "特定原材料由来成分を含まない",
    label: "特定原材料由来成分を含まない",
  },
];

function SelectFoodAllergy(props) {
  const { selectedValue, onSelect, isReadonly } = props;

  return (
    <div className="w-full h-full border border-solid border-[#B3B3B3] bg-white rounded-[10px]">
      <div className="w-full h-full flex flex-col items-center justify-center gap-2.5 px-[22px]">
        <SelectOption
          label={
            <p className="w-full text-start px-3">
              {foodAllergy[0].label}
            </p>
          }
          value={foodAllergy[0].value}
          onSelect={onSelect}
          isSelected={
            selectedValue === foodAllergy[0].value
          }
          width="100%"
          isReadonly={isReadonly}
          className="text-start"
        />
        <SelectOption
          label={
            <p className="w-full text-start px-3">
              {foodAllergy[1].label}
            </p>
          }
          value={foodAllergy[1].value}
          onSelect={onSelect}
          isSelected={selectedValue === foodAllergy[1].value}
          width="100%"
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

export default SelectFoodAllergy;
