import React, { useState } from "react";
import Popup from "./Popup";
import {
  DATE_TIME_FORMAT,
  caseStatus as caseStatusList,
  fileNameType,
  invoiceStatusColor,
  invoiceStatusLabel,
  invoiceStatus as invoiceStatusValue,
} from "app/constants";
import moment from "moment";
import { commonServices, projectServices } from "app/services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import messages from "../../../../services/api/messages";

function InvoiceCell(props) {
  const { width, invoiceStatus, invoiceInfo, caseInfo, setCaseInfo } = props;
  const [anchor, setAnchor] = useState(null);
  const { downloadPDFWithFileId } = commonServices;
  const handleLeftClickInvoice = () => {
    if (invoiceInfo?.generatedInvoiceExcel) {
      const caseId = caseInfo?.caseId || "";
      const billName = fileNameType["invoice"];
      const deliveryName = fileNameType["delivery"];
      const customerName = caseInfo?.customer?.companyNameKanji || "";
      const timeStamp = moment().format("YYYYMMDDHHmmss");

      Promise.all([
        downloadPDFWithFileId(
          invoiceInfo.generatedInvoiceExcel,
          `${billName}_${caseId}_${customerName}_${timeStamp}`
        ),
        downloadPDFWithFileId(
          invoiceInfo.generatedDeliverySlipExcel,
          `${deliveryName}_${caseId}_${customerName}_${timeStamp}`
        ),
      ])
        .then(() => {
          if (caseInfo?.caseStatus === caseStatusList.invoice) {
            projectServices
              .updateCaseStatusComplete(caseInfo._id)
              .then(() => {
                setCaseInfo({
                  ...caseInfo,
                  caseStatus: caseStatusList.completed,
                });
              })
              .catch(() => toast.error(messages.callApiFail));
          }
        })
        .catch(() => toast.error(messages.downloadFail));
    }
  };

  const handleRightClickInvoice = (e) => {
    e.preventDefault();
    if (invoiceStatus === invoiceStatusValue.submitted) setAnchor(e.target);
  };

  return (
    <>
      <div
        className="h-full border border-[#B3B3B3] px-2 py-4 shrink-0"
        style={{
          width: width,
        }}
      >
        <p className="text-sm leading-[18px] tracking-[.2em] truncate text-center">
          <button
            style={{
              color: invoiceStatusColor[invoiceStatus],
              cursor: "pointer",
            }}
            onClick={handleLeftClickInvoice}
            onContextMenu={handleRightClickInvoice}
          >
            {invoiceStatusLabel[invoiceStatus]}
          </button>
        </p>
      </div>

      <Popup
        id={`invoice-${invoiceInfo?._id}`}
        anchor={anchor}
        open={Boolean(anchor)}
      >
        <div className="flex flex-col">
          <button
            className="bg-[#c7c2c2] w-7 h-7 rounded-full ml-auto flex items-center justify-center"
            onClick={() => setAnchor(null)}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <p className="flex gap-2">
            最終更新日:{" "}
            {moment(invoiceInfo?.fileInfo?.updatedAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>
      </Popup>
    </>
  );
}

export default InvoiceCell;
